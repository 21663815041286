import { createAction } from "@reduxjs/toolkit";

// user admin action types
export const GET_USERS_LIST_BYADMIN = '[Users] Getting list users by admin...!';
export const GET_USERS_LIST_BYADMIN_SUCCESS = '[Users] Getting list users by admin with success...!';
export const CREATE_USER_BYADMIN = '[Users] user creating by admin...!';
export const CREATE_USER_BYADMIN_SUCCESS = '[Users] user create by admin with success!';
export const UPDATE_USER_BYADMIN = '[Users] updating user by admin...';
export const UPDATE_USER_BYADMIN_SUCCESS = '[Users] updating user by admin with success!';
export const REMOVE_USER_BYADMIN = '[Users] remove user by admin...';
export const REMOVE_USER_BYADMIN_SUCCESS = '[Users] remove user by admin with success!';

export const SET_SINGLE_LOGIN_BUTTON = '[Users] Setting single login button ...';
export const CLEAR_SINGLE_LOGIN_BUTTON = '[Users] updating user by admin with success!';


// user admin actions
export const getUsersListByAdmin = createAction(GET_USERS_LIST_BYADMIN);
export const getUsersListByAdminSuccess = createAction(GET_USERS_LIST_BYADMIN_SUCCESS);

export const setSingleLoginButton = createAction(SET_SINGLE_LOGIN_BUTTON);
export const clearSingleLoginButton = createAction(CLEAR_SINGLE_LOGIN_BUTTON);

export const createUserByAdmin = (user) => ({
    type: CREATE_USER_BYADMIN,
    payload: { user: user }
});

export const createUserByAdminSuccess = (user) => ({
    type: CREATE_USER_BYADMIN_SUCCESS,
    payload: { user: user }
});

export const updateUserByAdmin = (user) => ({
    type: UPDATE_USER_BYADMIN,
    payload: { user: user }
});

export const updateUserByAdminSuccess = (user) => ({
    type: UPDATE_USER_BYADMIN_SUCCESS,
    payload: { user: user }
});

export const removeUserByAdmin = (user) => ({
    type: REMOVE_USER_BYADMIN,
    payload: { user: user }
});

export const removeUserByAdminSuccess = (user) => ({
    type: REMOVE_USER_BYADMIN_SUCCESS,
    payload: { user: user }
});
