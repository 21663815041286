import React, { useEffect } from "react";
import { Container, Row, Col, Input } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import { CLIENT_ADMIN_CREATE_PROCEDURE, CLIENT_ADMIN_DELETE_PROCEDURE, CLIENT_ADMIN_EDIT_PROCEDURE } from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from "../../redux/actions/ui.actions";
import { getAdminListProcedures } from "../../redux/actions/procedures.actions";
import { customStyles } from '../../theme/assets/dataTableCustomStyles';

const ClientAdminProcedures = () => {
    const adminProceduresList = useSelector((state) => state.adminProcedures.adminProceduresList);

    const dispatch = useDispatch();

    const createProcedure = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_CREATE_PROCEDURE));
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Procedures';
    });

    useEffect(() => {
        dispatch(getAdminListProcedures());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Short name',
            selector: row => row.short_name,
            sortable: true,
        },
        {
            name: 'Need details',
            center: true,
            cell: (row) => (<Input type='checkbox' defaultChecked={row.need_details} disabled />)
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (<>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_EDIT_PROCEDURE.replace(':procedureId', row.id)} >
                    <FontAwesomeIcon icon={faPen} title="Edit" />
                </Link>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_DELETE_PROCEDURE.replace(':procedureId', row.id)} >
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Link>
            </>),
        }
    ];

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText="Procedures" />
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row className='pb-1 mb-2 mt-2'>
                <Col md={3}>
                    <h3 className='mb-4 mt-2'>Procedures</h3>
                </Col>
                <Col md={{ offset: 6, size: 3 }}>
                    <Button color={'success'} onClick={createProcedure} block>
                        Create new procedure
                    </Button>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    {adminProceduresList && adminProceduresList.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            Empty list
                        </div>
                    ) : (
                        <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search procedure"
                            columns={columns}
                            data={adminProceduresList} >
                            <DataTable
                                columns={columns}
                                data={adminProceduresList}
                                noHeader
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </DataTableExtensions>
                    )}
                </Col >
            </Row>
        </Container>
    )
};

export default ClientAdminProcedures;