import { Container, Row, Col } from 'reactstrap';
import { CSVLink } from "react-csv";
import AppDatePicker from '../../components/ui/AppDateRangePicker';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import { getBasicStudentReport } from '../../redux/actions/cubicle_logs.actions';
import { customStyles } from '../../theme/assets/dataTableCustomStyles';
import { useState } from 'react';
import Search from '../../components/ui/Search';
import { FORMAT_DATETIME_SHORT, isoStringToFormated, secondsToDurationFormated } from '../../utils/date_time';
import { CUBICLE_STATUS_UI_NAMES } from '../../redux/reducers/my_cubicle.reducer';

const ClientAdminStudentReport = () => {
    const dispatch = useDispatch();
    const reportRows = useSelector(state => state.cubicleLogs.detailedStudentReport.reportRows);
    const [paginationParams, setPaginationParams] = useState({});
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Student report';
    }, []);

    useEffect(() => {
        if (fromDate && toDate) {
            let fromDateEpoch = fromDate.getTime();
            let toDateEpoch = toDate.getTime();
            dispatch(getBasicStudentReport({ fromDateEpoch, toDateEpoch, paginationParams }));
        }
    }, [dispatch, fromDate, toDate, paginationParams]);

    const handleSearchClicked = (search) => {
        setPaginationParams({
            ...paginationParams,
            filter: search,
        })
    }

    const handleDateRangeChange = (fromDate, toDate) => {
        setFromDate(fromDate);
        setToDate(toDate);
    }

    const columns = [
        {
            name: 'Start',
            selector: row => row.createdAt,
            cell: row => isoStringToFormated(row.createdAt, FORMAT_DATETIME_SHORT),
            sortable: true,
        },
        {
            name: 'Student',
            selector: row => row.studentFullName,
            sortable: true,
        },
        {
            name: 'Request type',
            selector: row => CUBICLE_STATUS_UI_NAMES[row.cubicleStatus],
            sortable: true,
        },
        {
            name: 'Procedure(s) completed',
            sortable: false,
            cell: row => row.proceduresCompleted.join(' / '),
        },
        {
            name: 'Duration',
            selector: row => secondsToDurationFormated(row.totalSeconds),
            right: true,
        },
    ];

    return (
        <Container className='mb-2 min-vh-72'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Student Report" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3 className='mb-4 mt-2'>Student Report</h3>
                </Col>
            </Row>
            <Row className='pb-2 mb-2 mt-2'>
                <Col md={3} className='mb-2 mb-md-0 pb-1 pb-md-0'>
                    <AppDatePicker dateRangeChanged={handleDateRangeChange} />
                </Col>
                <Col md={3} className='mb-2 mb-md-0 pb-1 pb-md-0'>
                    <Search handleSearch={handleSearchClicked} inputPlaceHolder='Search students' />
                </Col>
                <Col md={{ offset: 3, size: 3 }} className='mb-2 mb-md-0 pb-1 pb-md-0'>
                    <CSVLink
                        data={reportRows}
                        filename='student-report.csv'
                        className="btn btn-success d-block w-100"
                        target="_blank"
                    >
                        Generate Report
                    </CSVLink>
                </Col>
            </Row>

            <Row className='mb-5'>
                <Col>
                    {reportRows && reportRows.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            No rows
                        </div>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={reportRows}
                            noHeader
                            pagination
                            highlightOnHover
                            customStyles={customStyles}
                        />
                    )}
                </Col >
            </Row>
        </Container>
    )
};

export default ClientAdminStudentReport;