import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import { FormGroup, Input } from 'reactstrap';
import { Button } from 'reactstrap';

const SpecialRequestModal = ({ modalOpen, setModalClosed, modalSubmit }) => {
    const isFullScreen = window.matchMedia('(max-width: 719px)').matches;
    const facultiesLoggedIn = useSelector((state) => state.facultiesLoggedIn);

    return (
        <Modal size='lg' isOpen={modalOpen} fullscreen={isFullScreen}>
            <ModalHeader toggle={setModalClosed} />
            <ModalBody>
                <Container className='px-md-5' fluid>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            faculty_requested: '',
                        }}
                        validationSchema={Yup.object({
                            faculty_requested: Yup.string()
                                .required('Please select a factulty member'),
                        })}
                        onSubmit={(values) => {
                            modalSubmit(values);
                        }}
                    >
                        {() => (
                            <Form>
                                <Row className='mt-1'>
                                    <Col>
                                        <h3 className='text-center mb-2'>Special request</h3>
                                    </Col>
                                </Row>
                                <Row className='align-items-center mt-2'>
                                    <Col md={{ offset: 1, size: 10 }} className='px-md-5 mb-2'>
                                        <p className='text-center'>Select the faculty member which you require a special request. Only faculty members who are online are available.</p>
                                        <FormGroup className='mt-4'>
                                            <Field type='select' name='faculty_requested' as={Input} >
                                                <option>Select a faculty member</option>
                                                {facultiesLoggedIn.map((faculty) => (
                                                    <option key={faculty.username} value={faculty.username}>
                                                        {faculty.first_name + ' ' + faculty.last_name}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name='faculty_requested' />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ offset: 3, size: 6 }} className='px-md-5 mb-5'>
                                        <Button color='success' type='submit' block>Submit special request</Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Container>
            </ModalBody>
        </Modal>
    );
};

export default SpecialRequestModal;
