import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { FormGroup, Input, Label, Button } from 'reactstrap';
import { createCubicleLog } from '../../redux/actions/cubicle_logs.actions';
import { CUBICLE_STATUS_CHECKOUT } from '../../redux/reducers/my_cubicle.reducer';
import { buildProcedureLabel } from '../../utils';

const CheckoutProceduresForm = () => {
    const dispatch = useDispatch();
    const proceduresList = useSelector(state => state.procedures.proceduresList);
    const [orderedProcedures, setOrderedProcedures] = useState([]);

    useEffect(() => {
        let orderedProcedures = [...proceduresList];
        orderedProcedures.sort((a, b) => {
            const concatA = a.need_details.toString() + a.name;
            const concatB = b.need_details.toString() + b.name;
            return concatA.localeCompare(concatB);
        });
        setOrderedProcedures(orderedProcedures);
    }, [proceduresList]);

    return (
        <Row className='mt-1 mb-5'>
            <Col className='mt-3'>
                <h4 className='pt-3 pb-3'>Select the procedure that you are completing</h4>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        procedures: [],
                        other_procedure: '',
                    }}
                    onSubmit={(values) => {
                        const cubicleLogData = {
                            cubicle_status: CUBICLE_STATUS_CHECKOUT,
                            ...values,
                        };
                        dispatch(createCubicleLog(cubicleLogData));
                    }}
                >
                    {() => (
                        <Form>
                            {orderedProcedures.map((procedure, index) => {
                                let procedureFormElement = (
                                    <FormGroup key={index} className={procedure.need_details ? 'mb-2' : 'mt-3'} check>
                                        <Field type='checkbox' name='procedures' value={procedure.id.toString()} id={'procedure-' + procedure.id} as={Input} />
                                        <Label for={'procedure-' + procedure.id} check>{buildProcedureLabel(procedure)}</Label>
                                    </FormGroup>
                                );

                                if (procedure.need_details) {
                                    return (
                                        <div key={'details' + index} className='d-flex align-items-center mt-2'>
                                            <div>
                                                {procedureFormElement}
                                            </div>
                                            <div className='ms-4'>
                                                <FormGroup className='ms-3'>
                                                    <Field type='text' name='other_procedure' placeholder='Please specify' as={Input} />
                                                    <ErrorMessage name='other_procedure' />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return procedureFormElement;
                                }
                            })}

                            <div>
                                <Button color='success' className='mt-2 px-5' type='submit'>
                                    <span className='d-inline-block fs-5 px-3'>Confirm</span>
                                </Button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    );
};

export default CheckoutProceduresForm;
