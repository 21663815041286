import { all, call, debounce, fork, put, takeEvery } from 'redux-saga/effects';
import {
    GET_ADMIN_LIST_CONFIGURATIONS,
    getAdminListConfigurationsSuccess,
    UPDATE_ADMIN_CONFIGURATION,
    updateAdminConfigurationSuccess,
} from '../actions/configurations.actions';

import {
    getConfigurations,
    updateConfiguration,
} from '../../services/configurationsService';

import { genericError } from '../actions/error.actions';
import {
    disableOverlay,
    enableOverlay,
    setToast,
} from '../actions/ui.actions';

import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getAdminListConfigurationsEffect
 */
function* getAdminListConfigurationsEffect({ payload }) {
    yield put(enableOverlay(`Loading configurations (${payload})`));
    try {
        const configurationList = yield call(getConfigurations, payload);
        yield put(getAdminListConfigurationsSuccess(configurationList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateAdminConfigurationEffect
 */
function* updateAdminConfigurationEffect({ payload }) {
    try {
        const updatedConfiguration = yield call(updateConfiguration, payload);
        yield put(updateAdminConfigurationSuccess(updatedConfiguration));
        yield put(
            setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The configuration has been modified succesfully!',
            })
        );
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

export function* watchGetAdminListConfigurations() {
    yield takeEvery(GET_ADMIN_LIST_CONFIGURATIONS, getAdminListConfigurationsEffect);
}

export function* watchUpdateAdminConfiguration() {
    yield debounce(1000, UPDATE_ADMIN_CONFIGURATION, updateAdminConfigurationEffect);
}

function* configurationsSaga() {
    yield all([
        fork(watchGetAdminListConfigurations),
        fork(watchUpdateAdminConfiguration),
    ]);
}

export default configurationsSaga;
