import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_PROCEDURE,
    DELETE_PROCEDURE,
    UPDATE_PROCEDURE,
    GET_ADMIN_LIST_PROCEDURES,
    GET_LIST_PROCEDURES,
    getAdminListProceduresSuccess,
    getProceduresSuccess,
} from '../actions/procedures.actions';
import { createProcedure, getProcedures, removeProcedure, updateProcedure } from '../../services/proceduresService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from '../actions/ui.actions';
import { CLIENT_ADMIN_PROCEDURES } from '../../navigation/ROUTE_CONSTANTS';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getListProceduresEffect
 */
function* getListProceduresEffect() {
    try {
        const proceduresList = yield call(getProcedures);
        if (proceduresList) {
            yield put(getProceduresSuccess(proceduresList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getAdminListProceduresEffect
 */
function* getAdminListProceduresEffect() {
    yield put(enableOverlay('Loading procedures ...'));
    try {
        const proceduresList = yield call(getProcedures);
        yield put(getAdminListProceduresSuccess(proceduresList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createProcedureEffect
 */
function* createProcedureEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating procedure ...'));
        const procedure = yield call(createProcedure, payload);
        if (procedure) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The procedure has been created!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_PROCEDURES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateProcedureEffect
 */
function* updateProcedureEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating procedure ...'));
        const procedure = yield call(updateProcedure, payload);
        if (procedure) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The procedure has been updated!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_PROCEDURES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteProcedureEffect
 */
function* deleteProcedureEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting procedure ...'));
        const procedure = yield call(removeProcedure, payload);
        if (procedure) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The procedure has been deleted!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_PROCEDURES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchGetListProcedures() {
    yield takeEvery(GET_LIST_PROCEDURES, getListProceduresEffect);
}

export function* watchGetAdminListProcedures() {
    yield takeEvery(GET_ADMIN_LIST_PROCEDURES, getAdminListProceduresEffect);
}

export function* watchCreateProcedure() {
    yield takeEvery(CREATE_PROCEDURE, createProcedureEffect);
}

export function* watchUpdateProcedure() {
    yield takeEvery(UPDATE_PROCEDURE, updateProcedureEffect);
}

export function* watchDeleteProcedure() {
    yield takeEvery(DELETE_PROCEDURE, deleteProcedureEffect);
}

function* proceduresSaga() {
    yield all([
        fork(watchGetListProcedures),
        fork(watchGetAdminListProcedures),
        fork(watchCreateProcedure),
        fork(watchUpdateProcedure),
        fork(watchDeleteProcedure),
    ]);
}

export default proceduresSaga;
