import {
    GET_ADMIN_LIST_CONFIGURATIONS_SUCCESS,
    UPDATE_ADMIN_CONFIGURATION_SUCCESS,
} from '../actions/configurations.actions';

const initialConfigurationsState = {};

export const SESSION_TIME_FACULTY = 'user-session-max-minutes-faculty';
export const SESSION_TIME_STUDENT = 'user-session-max-minutes-student';

export const AdminConfigurationsReducer = (state = initialConfigurationsState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                [action.payload['config_key']]: action.payload['value'],
            };
        case UPDATE_ADMIN_CONFIGURATION_SUCCESS:
            return {
                ...state,
                [action.payload['config_key']]: action.payload['value'],
            };
        default:
            return state;
    }
};
