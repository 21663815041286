import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import CreateUserForm from '../../components/client_admins/CreateUserForm';
import { CLIENT_ADMIN_USERS } from '../../navigation/ROUTE_CONSTANTS';


function ClientAdminCreateUser() {

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Create user ';
    });

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Create User" crumbList={[{ linkTo: CLIENT_ADMIN_USERS, label: 'Users' }]} />
                </Col>
            </Row>
            <CreateUserForm />
        </Container>
    )
}

export default ClientAdminCreateUser