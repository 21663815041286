import { Button, Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

const Search = ({ handleSearch, inputPlaceHolder = 'Search' }) => {
    const [searchString, setSearchString] = useState('');

    const handleOnChange = (e) => {
        setSearchString(e.target.value);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        handleSearch(searchString);
    };

    const resetSearch = (e) => {
        e.preventDefault();
        setSearchString('');
        handleSearch('');
    };

    return (
        <div className="position-relative">
            <Form onSubmit={handleOnSubmit}>
                <Input
                    placeholder={inputPlaceHolder}
                    className="pe-5 rounded-0 position-relative"
                    onChange={handleOnChange}
                    value={searchString}
                />
                {searchString.length > 0 && (
                    <Button
                        className="btn-hover position-absolute end-0 top-0 mt-1"
                        color="transparent"
                        style={{ marginRight: '35px' }}
                        size="sm"
                        type="button"
                        onClick={(e) => resetSearch(e)}
                    >
                        <FontAwesomeIcon className="fs-5 mt-1" icon={faXmark} />
                    </Button>
                )}
                <Button
                    className="btn-hover position-absolute end-0 top-0 mt-1"
                    color="transparent"
                    size="sm"
                >
                    <FontAwesomeIcon
                        className="fs-5 mt-1"
                        icon={faMagnifyingGlass}
                    />
                </Button>
            </Form>
        </div>
    );
};

export default Search;
