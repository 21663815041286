import {createAction} from "@reduxjs/toolkit";

// CubicleGroup type
export const CREATE_CUBICLE = '[CUBICLE] Cubicle created';
export const UPDATE_CUBICLE = '[CUBICLE] Cubicle updated';
export const DELETE_CUBICLE = '[CUBICLE] Cubicle deleted';
export const GET_LIST_CUBICLES = '[CUBICLES] Getting list cubicles';
export const GET_LIST_CUBICLES_SUCCESS = '[CUBICLES] Getting list cubicles success!';
export const GET_LIST_UNASSIGNED_CUBICLES = '[CUBICLES] Getting unassigned cubicles';
export const GET_LIST_UNASSIGNED_CUBICLES_SUCCESS = '[CUBICLES] Getting unassigned cubicles success!';
export const GET_ADMIN_LIST_CUBICLES = '[CUBICLES] Getting admin list cubicles';
export const GET_ADMIN_LIST_CUBICLES_SUCCESS = '[CUBICLES] Getting admin list cubicles success!';
export const GET_CUBICLE_QUEUE = '[CUBICLES] Getting cubicle queue';
export const GET_CUBICLE_QUEUE_SUCCESS = '[CUBICLES] Getting cubicle queue success!';

// CubicleGroup actions
export const createCubicle = createAction(CREATE_CUBICLE);
export const updateCubicle = createAction(UPDATE_CUBICLE);
export const deleteCubicle = createAction(DELETE_CUBICLE);
export const getCubicles = createAction(GET_LIST_CUBICLES);
export const getCubiclesSuccess = createAction(GET_LIST_CUBICLES_SUCCESS);
export const getUnassignedCubicles = createAction(GET_LIST_UNASSIGNED_CUBICLES);
export const getUnassignedCubiclesSuccess = createAction(GET_LIST_UNASSIGNED_CUBICLES_SUCCESS);
export const getAdminListCubicles = createAction(GET_ADMIN_LIST_CUBICLES);
export const getAdminListCubiclesSuccess = createAction(GET_ADMIN_LIST_CUBICLES_SUCCESS);
export const getCubicleQueue = createAction(GET_CUBICLE_QUEUE);
export const getCubicleQueueSuccess = createAction(GET_CUBICLE_QUEUE_SUCCESS);
