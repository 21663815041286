import React, { useEffect } from "react";
import { Container, Row, Col, Input } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import { CLIENT_ADMIN_CREATE_CUBICLE, CLIENT_ADMIN_DELETE_CUBICLE, CLIENT_ADMIN_EDIT_CUBICLE } from '../../navigation/ROUTE_CONSTANTS';
import { getAdminListCubicles } from "../../redux/actions/cubicles.actions";
import { setNavigateTo } from "../../redux/actions/ui.actions";
import { getCubicleGroups } from "../../redux/actions/cubicle_groups.actions";
import CubicleBadge from "../../components/ui/CubicleBadge";
import { customStyles } from '../../theme/assets/dataTableCustomStyles';

const ClientAdminCubicles = () => {
    const cubiclesAdminList = useSelector((state) => state.cubiclesAdmin.cubiclesAdminList);
    const cubicleGroupMapIdToName = useSelector((state) => state.cubicleGroups.mapIdToName);

    const dispatch = useDispatch();

    const createCubicle = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_CREATE_CUBICLE));
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Cubicles';
    });

    useEffect(() => {
        dispatch(getCubicleGroups());
        dispatch(getAdminListCubicles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Color',
            selector: row => <CubicleBadge cubicleColor={row.color} cubicleName={row.name} />,
            sortable: true,
            center: true,
            className: 'text-body'
        },
        {
            name: 'Group',
            selector: row => cubicleGroupMapIdToName[row.group] || '',
            sortable: true,
            center: true
        },
        {
            name: 'Is Enabled?',
            center: true,
            cell: (row) => (<Input type='checkbox' defaultChecked={row.is_enabled} disabled />)
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (<>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_EDIT_CUBICLE.replace(':cubicleId', row.id)} >
                    <FontAwesomeIcon icon={faPen} title="Edit" />
                </Link>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_DELETE_CUBICLE.replace(':cubicleId', row.id)} >
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Link>
            </>),
        }
    ];

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText="Cubicles" />
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row className='pb-1 mb-2 mt-2'>
                <Col md={3}>
                    <h3 className='mb-4 mt-2'>Cubicles</h3>
                </Col>
                <Col md={{ offset: 6, size: 3 }}>
                    <Button color={'success'} onClick={createCubicle} block>
                        Create new cubicle
                    </Button>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    {cubiclesAdminList && cubiclesAdminList.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            Empty list
                        </div>
                    ) : (
                        <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search cubicle"
                            columns={columns}
                            data={cubiclesAdminList} >
                            <DataTable
                                columns={columns}
                                data={cubiclesAdminList}
                                noHeader
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </DataTableExtensions>
                    )}
                </Col >
            </Row>
        </Container>
    )
};

export default ClientAdminCubicles;