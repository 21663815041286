import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, FormGroup, Input, Label } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import * as Yup from 'yup';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { CUBICLE_AVAILABLE_COLORS } from '../../redux/reducers/cubicles.reducers';
import CubicleBadge from '../../components/ui/CubicleBadge';
import { createCubicle, updateCubicle } from '../../redux/actions/cubicles.actions';
import { getOneCubicle } from '../../services/cubiclesService';
import { getCubicleGroups } from '../../redux/actions/cubicle_groups.actions';
import { CLIENT_ADMIN_CUBICLES } from '../../navigation/ROUTE_CONSTANTS';

function ClientAdminCubicleForm() {

    const dispatch = useDispatch();
    const { cubicleId } = useParams();
    const [initialFornmValues, setInitialFornmValues] = useState({
        name: '',
        color: '',
        group: '',
        is_enabled: true,
    });
    const [pageTitle, setPageTitle] = useState('');
    const [groupOptions, setGroupOptions] = useState([]);
    const [cubicleColorOptions, setCubicleColorOptions] = useState([]);

    const cubicleGroupMapIdToName = useSelector((state) => state.cubicleGroups.mapIdToName);

    useEffect(() => {
        dispatch(getCubicleGroups());
        if (cubicleId) {
            setPageTitle('Edit Cubicle');
            getOneCubicle(cubicleId).then((data) => {
                setInitialFornmValues({
                    name: data.name,
                    color: data.color,
                    group: data.group,
                    is_enabled: data.is_enabled,
                });
            })
        } else {
            setPageTitle('Create Cubicle');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cubicleId]);

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - ' + pageTitle;
    }, [pageTitle]);

    useEffect(() => {
        let groupOptions = Object.keys(cubicleGroupMapIdToName).map((key) => {
            return {
                value: key,
                label: cubicleGroupMapIdToName[key]
            }
        }).sort();

        groupOptions.unshift({
            value: null,
            label: '-- No Group --'
        });

        setGroupOptions(groupOptions);

        let cubicleColorOptions = CUBICLE_AVAILABLE_COLORS.map((color) => {
            return {
                value: color,
                label: color
            }
        });
        setCubicleColorOptions(cubicleColorOptions);

    }, [cubicleGroupMapIdToName]);


    const formatColorOptionLabel = ({ value }) => (
        <CubicleBadge cubicleColor={value} cubicleName="#####" />
    );

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText={pageTitle} crumbList={[{ linkTo: CLIENT_ADMIN_CUBICLES, label: 'Cubicles' }]} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto'>
                        <h4 className='pt-2 pb-3 text-center'>{pageTitle}</h4>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialFornmValues}
                            validationSchema={Yup.object({
                                name: Yup.string()
                                    .min(1, 'Name must be at least 1 characters length.')
                                    .max(12, 'Name must be smaller than 12 characters length.')
                                    .required('Please enter a name'),
                                color: Yup.string()
                                    .required('Please select a color'),
                                group: Yup.string()
                                    .nullable()
                            })}
                            onSubmit={(values) => {
                                let formData = {
                                    name: values.name,
                                    color: values.color,
                                    group: values.group,
                                    is_enabled: values.is_enabled,
                                }

                                if (cubicleId) {
                                    formData.id = cubicleId;
                                    dispatch(updateCubicle(formData));
                                } else {
                                    dispatch(createCubicle(formData));
                                }
                            }}
                        >{({ values, setFieldValue }) => (
                            <Form>
                                <FormGroup>
                                    <Field type="text" name="name" placeholder="Name" as={Input} />
                                    <ErrorMessage component="div" className="text-danger" name="name" />
                                </FormGroup>
                                <FormGroup>
                                    <Field id="color" name="color" as={Select}
                                        placeholder="Color"
                                        value={values.color ? { value: values.color, label: formatColorOptionLabel(values.color) } : ''}
                                        onChange={option => setFieldValue('color', option.value)}
                                        formatOptionLabel={formatColorOptionLabel}
                                        options={cubicleColorOptions}
                                        isSearchable={false}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="color" />
                                </FormGroup>

                                <FormGroup>
                                    <Field id="group" name="group" as={Select}
                                        placeholder="Group"
                                        value={values.group ? { value: values.group, label: cubicleGroupMapIdToName[values.group] } : ''}
                                        onChange={option => setFieldValue('group', option.value)}
                                        options={groupOptions}
                                        isSearchable={false}
                                    />
                                    <ErrorMessage component="div" className="text-danger" name="group" />
                                </FormGroup>

                                <FormGroup >
                                    <Field type="checkbox" id="is_enabled" name="is_enabled" as={Input} />
                                    <Label className='mx-2' check for="is_enabled">Is Enabled?</Label>
                                    <ErrorMessage component="div" className="text-danger" name="is_enabled" />
                                </FormGroup>

                                <div className='text-center'>
                                    <Button color='success' className='mt-2 px-5' type='submit'>
                                        <span className='d-inline-block fs-5 px-3'>{pageTitle}</span>
                                    </Button>
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </div>
                </Col>
            </Row >

        </Container>
    )
}

export default ClientAdminCubicleForm;