import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_CUBICLE_GROUPS } from './API_CONSTANTS';

export const getCubicleGroups = (paginationParams) => {
    paginationParams = paginationParams ? paginationParams : {};

    const url = API_CUBICLE_GROUPS + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Cubicle groups loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get cubile groups error: ', err);
                    reject('Get cubile groups error');
                });
        } catch (error) {
            console.error('Get cubile groups error: ', error);
            reject('Get cubile groups error');
        }
    });
};

export const getOneCubicleGroup = (cubicleGroupId) => {
    const url = API_CUBICLE_GROUPS + '/' + cubicleGroupId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Get one cubicle group: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one cubicle group error: ', err);
                    reject('Get one cubicle group error');
                });
        } catch (error) {
            console.error('Get one cubicle group error: ', error);
            reject('Get one cubicle group error');
        }
    });
}

// Create cublicle group
export const createCubicleGroup = (cubicleGroup) => {
    const url = API_CUBICLE_GROUPS;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, cubicleGroup)
                .then((res) => {
                    console.log('Cubicle group created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create cubicle group error: ', err);
                    reject('Create cubicle group error');
                });
        } catch (error) {
            console.error('Create cubicle group error: ', error);
            reject('Create cubicle group error');
        }
    });
}

export const updateCubicleGroup = (cubicleGroup) => {
    const url = API_CUBICLE_GROUPS + '/' + cubicleGroup.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, cubicleGroup)
                .then((res) => {
                    console.log('Cubicle group updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update cubicle group error: ', err);
                    reject('Update cubicle group error');
                });
        } catch (error) {
            console.error('Update cubicle group error: ', error);
            reject('Update cubicle group error');
        }
    });
}

export const removeCubicleGroup = (cubicleGroupId) => {
    const url = API_CUBICLE_GROUPS + '/' + cubicleGroupId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Cubicle group removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove cubicle group error: ', err);
                    reject('Remove cubicle group error');
                });
        } catch (error) {
            console.error('Remove cubicle group error: ', error);
            reject('Remove cubicle group error');
        }
    });
}
