import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Table } from "reactstrap";
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { getUser } from '../../redux/actions/users.actions';
import { USER_ROLE_UI_NAMES } from '../../redux/reducers/auth.reducer';

export const Profile = () => {
    const dispatch = useDispatch();
    const userLogged = useSelector(state => state.users.userLogged);

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText="Profile" />
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row className='pb-1 mb-2 mt-2'>
                <Col md={3}>
                    <h3 className='mb-4 mt-2'>Profile</h3>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col className='table-responsive-sm mx-auto' md={4}>
                    <Table className='border-dark border-opacity-25 text-nowrap' bordered>
                        <tbody>
                            <tr>
                                <td className='border-start-0 h5'>Username</td>
                                <td className='border-end-0'>{userLogged.username}</td>
                            </tr>
                            <tr>
                                <td className='border-start-0 h5'>Name</td>
                                <td className='border-end-0'>{userLogged.first_name} {userLogged.last_name}</td>
                            </tr>
                            <tr>
                                <td className='border-start-0 h5'>Role</td>
                                <td className='border-end-0'>{USER_ROLE_UI_NAMES[userLogged.role]}</td>
                            </tr>
                            <tr>
                                <td className='border-start-0 h5'>Email</td>
                                <td className='border-end-0'>{userLogged.email}</td>
                            </tr>

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default Profile;
