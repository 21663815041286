import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { disableOverlay, enableOverlay, setToast } from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";
import {
  createInvoiceSuccess, CREATE_INVOICE, DELETE_INVOICE, getSelectedClientInvoicesSuccess, getSelectedInvoiceClientSuccess,
  GET_SELECTED_CLIENT_INVOICES, GET_SELECTED_INVOICE_CLIENT, UPDATE_INVOICE
} from "../actions/admin.invoices.actions"
import * as invoicesApi from "../../services/admin.invoices.services"
import { ADMIN_INVOICES } from "../../navigation/ROUTE_CONSTANTS";
import { getClient } from "../../services/clientsService";
import { getInvoices } from "../../services/invoicesService";
import { TOAST_SEVERITY_SUCCESS } from "../reducers/ui.reducer";

/**
 * createInvoiceEffect
 */
function* createInvoiceEffect({ payload }) {
  try {
    yield put(enableOverlay('Creating invoice...'));
    const invoice = yield call(invoicesApi.postInvoice, payload);
    if (invoice) {
      yield put(createInvoiceSuccess(invoice));
      yield put(disableOverlay());
      window.location.href = ADMIN_INVOICES;
    }
  } catch (err) {
    console.log('error:', err);
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

/**
 * deleteInvoiceEffect
 */
function* deleteInvoiceEffect({ payload }) {
  try {
    yield put(enableOverlay('Deleting invoice...'));
    yield call(invoicesApi.deleteInvoice, payload);
    yield put(disableOverlay());
    yield setToast({
      severity: TOAST_SEVERITY_SUCCESS,
      summary: 'Invoice deleted',
      life: 5000
    })
    window.location.href = ADMIN_INVOICES;
  } catch (err) {
    console.log('error:', err);
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

/**
 * updateInvoiceEffect
 */
function* updateInvoiceEffect({ payload }) {
  try {
    yield put(enableOverlay('Updating invoice...'));
    yield call(invoicesApi.updateInvoice, payload);
    yield put(disableOverlay());
    yield setToast({
      severity: TOAST_SEVERITY_SUCCESS,
      summary: 'Invoice updated',
      life: 5000
    })
    window.location.href = ADMIN_INVOICES;
  } catch (err) {
    console.log('error:', err);
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

/**
 * getSelectedInvoiceClientEffect
 */
function* getSelectedInvoiceClientEffect({ payload }) {
  try {
    yield put(enableOverlay('Loading invoice client...'));
    const client = yield call(getClient, payload);
    yield put(getSelectedInvoiceClientSuccess(client));
    yield put(disableOverlay());
  } catch (err) {
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

/**
 * getSelectedClientInvoicesEffect
 */
function* getSelectedClientInvoicesEffect({ payload }) {
  try {
    yield put(enableOverlay('Loading client invoices ...'));
    const invoices = yield call(getInvoices, { client_id: payload, limit: 5, sortBy: 'invoiceId', order: 'desc' });
    yield put(getSelectedClientInvoicesSuccess(invoices.results));
    yield put(disableOverlay());
  } catch (err) {
    yield put(genericError(err));
    yield put(disableOverlay());
  }
}

export function* watchCreateInvoice() {
  yield takeEvery(CREATE_INVOICE, createInvoiceEffect);
}

export function* watchDeleteInvoice() {
  yield takeEvery(DELETE_INVOICE, deleteInvoiceEffect);
}

export function* watchUpdateInvoice() {
  yield takeEvery(UPDATE_INVOICE, updateInvoiceEffect);
}

export function* watchGetSelectedInvoiceClient() {
  yield takeEvery(GET_SELECTED_INVOICE_CLIENT, getSelectedInvoiceClientEffect);
}

export function* watchGetSelectedClientInvoices() {
  yield takeEvery(GET_SELECTED_CLIENT_INVOICES, getSelectedClientInvoicesEffect);
}

function* invoicesSaga() {
  yield all([
    fork(watchCreateInvoice),
    fork(watchDeleteInvoice),
    fork(watchUpdateInvoice),
    fork(watchGetSelectedInvoiceClient),
    fork(watchGetSelectedClientInvoices),
  ]);
}

export default invoicesSaga;
