import { Container } from 'reactstrap';
import { STUDENT_HOME } from '../../navigation/ROUTE_CONSTANTS';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CUBICLE_STATUS_HELP, CUBICLE_STATUS_SPECIAL_REQUEST } from '../../redux/reducers/my_cubicle.reducer';
import StudentCubicleQueue from '../../components/student/StudentCubicleQueue';

const RequestHelp = () => {
    const dispatch = useDispatch();
    const myCubicleStatus = useSelector(state => state.myCubicle.cubicle_status);

    useEffect(() => {
        if (myCubicleStatus && !([CUBICLE_STATUS_HELP, CUBICLE_STATUS_SPECIAL_REQUEST].includes(myCubicleStatus))) {
            dispatch(setNavigateTo(STUDENT_HOME));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myCubicleStatus]);

    return (
        <Container className='px-4 px-md-auto min-vh-56'>
            <StudentCubicleQueue />
        </Container>
    );
};

export default RequestHelp;
