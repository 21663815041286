import { API_ERROR, CLEAN_ERROR, GENERIC_ERROR } from "../actions/error.actions";

const initialErrorState = { apiError: null, lastError: null };

export const ErrorReducer = (state = initialErrorState, action) => {
    switch (action.type) {
        case API_ERROR:
            return { ...state, apiError: action.payload };
        case GENERIC_ERROR:
            return { ...state, lastError: action.payload };
        case CLEAN_ERROR:
            return { ...state, apiError: null, lastError: null };
        default:
            return state;
    }
}
