import { createAction } from '@reduxjs/toolkit';

// Configurations type
export const GET_ADMIN_LIST_CONFIGURATIONS = '[ADMIN CONFIGURATIONS] Getting admin list procedures';
export const GET_ADMIN_LIST_CONFIGURATIONS_SUCCESS = '[ADMIN CONFIGURATIONS] Getting admin procedures success!';

export const UPDATE_ADMIN_CONFIGURATION = '[ADMIN CONFIGURATION] updating admin configuration!';
export const UPDATE_ADMIN_CONFIGURATION_SUCCESS = '[ADMIN CONFIGURATION] updating admin configuration with success!';

// Configurations actions
export const getAdminListConfigurations = createAction(GET_ADMIN_LIST_CONFIGURATIONS);
export const getAdminListConfigurationsSuccess = createAction(GET_ADMIN_LIST_CONFIGURATIONS_SUCCESS);

export const updateAdminConfiguration = createAction(UPDATE_ADMIN_CONFIGURATION);
export const updateAdminConfigurationSuccess = createAction(UPDATE_ADMIN_CONFIGURATION_SUCCESS);
