import { Container, Row, Col } from 'reactstrap';

const Terms = () => (
    <Container className='mb-5 px-4 px-md-auto min-vh-60'>
        <Row>
            <Col md={12}>
                <h3 className='mt-5 mb-4 pt-2 pb-2'>Terms &amp; Conditions</h3>
            </Col>
            <Col md={10}>
                <p className='mb-4'>
                    This Website provides links to other websites, both public and private, for informational purposes.
                    UMCA makes no representations, guarantees or warranties as to the accuracy, completeness, currency or suitability for any purpose of the information provided via this website.
                    UMCA specifically disclaims any and all liability and responsibility for any claims or damage that may arise as a result of the UMCA providing the website or the information it contains,
                    or that may arise in any way with respect to any websites maintained by third parties and linked to this site.
                </p>

            </Col>
        </Row>
    </Container>
);

export default Terms;