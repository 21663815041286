import { CREATE_USER_SUCCESS, GET_FACULTY_LIST_SUCCESS, GET_USER_SUCCESS, RESET_USER_STATE, RETRIEVE_FACULTIES_LOGGED_IN_SUCCESS, SET_WEBSOCKET_URL, UPDATE_USER_PROFILE_SUCCESS } from "../actions/users.actions";

const initialUsersState = {
    emailRegister: null,
    userLogged: null,
    facultyList: [],
    websocketUrl: null,
};

export const UsersReducer = (state = initialUsersState, action) => {
    switch (action.type) {
        case CREATE_USER_SUCCESS:
            return { ...state, emailRegister: action.payload };
        case GET_USER_SUCCESS:
            return { ...state, userLogged: action.payload };
        case UPDATE_USER_PROFILE_SUCCESS:
            return { ...state, userLogged: action.payload }
        case GET_FACULTY_LIST_SUCCESS:
            return { ...state, facultyList: action.payload };
        case SET_WEBSOCKET_URL:
            return { ...state, websocketUrl: action.payload };
        case RESET_USER_STATE:
            return initialUsersState;
        default:
            return state;
    }
}


const initialFacultiesLoggedInState = [];

export const FacultiesLoggedInReducer = (state = initialFacultiesLoggedInState, action) => {
    switch (action.type) {
        case RETRIEVE_FACULTIES_LOGGED_IN_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
