import { Container, Row, Col } from 'reactstrap';

const Help = () => (
    <Container className='mb-5 px-4 px-md-auto min-vh-60'>
        <Row>
            <Col md={12}>
                <h3 className='mt-5 mb-4 pt-2 pb-2'>Help</h3>
            </Col>
            <Col md={10}>
                <p className='mb-4'>
                    © Medical Clinic App. All rights reserved
                </p>

            </Col>
        </Row>
    </Container>
);

export default Help;