import {createAction} from "@reduxjs/toolkit";

// CubicleLog type
export const CREATE_CUBICLE_LOG = '[CUBICLE_LOG] Creating cubicle log';
export const UPDATE_CUBICLE_LOG = '[CUBICLE_LOG] Updating cubicle log';
export const SET_OCCUPIED_CUBICLE_LOG = '[CUBICLE_LOG] Setting occupied cubicle log';
export const SET_UNOCCUPIED_CUBICLE_LOG = '[CUBICLE_LOG] Setting unoccupied cubicle log';

export const GET_BASIC_FACULTY_REPORT = '[CUBICLE_LOG] Getting basic faculty report';
export const GET_BASIC_FACULTY_REPORT_SUCCESS = '[CUBICLE_LOG] Getting basic faculty report success!';

export const GET_BASIC_STUDENT_REPORT = '[CUBICLE_LOG] Getting basic student report';
export const GET_BASIC_STUDENT_REPORT_SUCCESS = '[CUBICLE_LOG] Getting basic student report success!';

// CubicleLog actions
export const createCubicleLog = createAction(CREATE_CUBICLE_LOG);
export const updateCubicleLog = createAction(UPDATE_CUBICLE_LOG);
export const setOccupiedCubicleLog = createAction(SET_OCCUPIED_CUBICLE_LOG);
export const setUnoccupiedCubicleLog = createAction(SET_UNOCCUPIED_CUBICLE_LOG);

export const getBasicFacultyReport = createAction(GET_BASIC_FACULTY_REPORT);
export const getBasicFacultyReportSuccess = createAction(GET_BASIC_FACULTY_REPORT_SUCCESS);

export const getBasicStudentReport = createAction(GET_BASIC_STUDENT_REPORT);
export const getBasicStudentReportSuccess = createAction(GET_BASIC_STUDENT_REPORT_SUCCESS);
