import { convertInvoiceRowToUI } from '../../utils';
import { SET_ADMIN_INVOICES_STATE, CREATE_INVOICE_SUCCESS, RESET_INVOICE, GET_SELECTED_INVOICE_CLIENT_SUCCESS, GET_SELECTED_CLIENT_INVOICES_SUCCESS } from '../actions/admin.invoices.actions';

const initialAdminInvoicesState = {
    payInvoiceModal: {
        open: false,
    },
    newEditInvoiceModal: {
        open: false
    },
    customers: [],
    openSideBar: {
        open: false,
    },
    openAccountManagement: {
        open: false,
        selectedInvoice: {},
    },
    selectedInvoiceAccount: {},
    selectedAccountInvoices: [],
    deleteAccountModal: {
        open: false
    },
    enableAccountModal: {
        open: false
    },
    disableAccountModal: {
        open: false
    },
    pdfInvoiceModal: {
        open: false,
        invoice: {}
    },
    sendReminderModal: {
        open: false,
        invoice: {}
    }
};

export const AdminInvoicesReducer = (state = initialAdminInvoicesState, action) => {
    switch (action.type) {
        case SET_ADMIN_INVOICES_STATE:
            return { ...state, ...action.payload };
        case GET_SELECTED_INVOICE_CLIENT_SUCCESS:
            state.selectedInvoiceAccount = action.payload;
            return state;
        case GET_SELECTED_CLIENT_INVOICES_SUCCESS:
            let invoicesList = [];
            // Injecting fields not retured by backend yet
            // Renaming fields
            for (let i in action.payload) {
                invoicesList.push(convertInvoiceRowToUI(action.payload[i]));
            }
            state.selectedAccountInvoices = invoicesList;
            return state;
        default:
            return state;
    }
}

const initialInvoiceState = {};

export const AdminInvoiceReducer = (state = initialInvoiceState, action) => {
    switch (action.type) {
        case CREATE_INVOICE_SUCCESS:
            return { ...state, ...action.payload }
        case RESET_INVOICE:
            return initialInvoiceState;
        default:
            return state;
    }
}
