import { Switch } from "react-router-dom";
import { GuardedRoute, GuardProvider } from "react-router-guards";
import { useSelector } from "react-redux";
import {
    CLIENT_ADMIN_CREATE_CUBICLE_GROUP,
    CLIENT_ADMIN_CREATE_CUBICLE,
    CLIENT_ADMIN_CREATE_PROCEDURE,
    CLIENT_ADMIN_CREATE_USER,
    CLIENT_ADMIN_CUBICLE_GROUPS,
    CLIENT_ADMIN_CUBICLES,
    CLIENT_ADMIN_DELETE_CUBICLE_GROUP,
    CLIENT_ADMIN_DELETE_CUBICLE,
    CLIENT_ADMIN_DELETE_PROCEDURE,
    CLIENT_ADMIN_DELETE_USER,
    CLIENT_ADMIN_EDIT_CUBICLE_GROUP,
    CLIENT_ADMIN_EDIT_CUBICLE,
    CLIENT_ADMIN_EDIT_PROCEDURE,
    CLIENT_ADMIN_EDIT_USER,
    CLIENT_ADMIN_PROCEDURES,
    CLIENT_ADMIN_USERS,
    FACULTY_HOME,
    FACULTY_MY_ACCOUNT,
    LOGIN_FORM,
    LOGOUT,
    PAGES_ABOUT,
    STUDENT_CHECKOUT,
    STUDENT_HOME,
    STUDENT_MY_ACCOUNT,
    STUDENT_REQUEST_HELP,
    STUDENT_REQUEST_SUBMITTED,
    USER_HOME,
    CUBICLES_MAP,
    PAGES_PRIVACY,
    PAGES_TERMS,
    PAGES_HELP,
    PAGES_CONTACT,
    USER_PROFILE,
    CLIENT_ADMIN_FACULTY_REPORT,
    CLIENT_ADMIN_STUDENT_REPORT,
    CLIENT_ADMIN_LOGOFF_TIMES,
    CLIENT_ADMIN_CREATE_LOGOFF_TIME,
    CLIENT_ADMIN_EDIT_LOGOFF_TIME,
    CLIENT_ADMIN_DELETE_LOGOFF_TIME,
    PAGES_NOT_AUTHORIZED,
} from "./ROUTE_CONSTANTS";
import { USER_ROLE_CLIENT_ADMIN, USER_ROLE_FACULTY, USER_ROLE_STUDENT } from "../redux/reducers/auth.reducer";

// General Pages
import Login from "../pages/general/Login";
import Logout from "../pages/general/Logout";
import About from "../pages/general/About";
import NotFound from "../pages/error/NotFound";

// Student Pages
import StudentHome from "../pages/students/StudentHome";
import AccountStudent from "../pages/students/AccountStudent";
import RequestSubmitted from "../pages/students/RequestSubmitted";
import RequestHelp from "../pages/students/RequestHelp";
import Checkout from "../pages/students/Checkout";

// Faculty Pages
import AccountFaculty from "../pages/faculties/AccountFaculty";
import FacultyHome from "../pages/faculties/FacultyHome";

// Client Admin Pages
import ClientAdminUsers from "../pages/client_admins/ClientAdminUsers";
import ClientAdminCreateUser from "../pages/client_admins/ClientAdminCreateUser";
import ClientAdminEditUser from "../pages/client_admins/ClientAdminEditUser";
import ClientAdminDeleteUser from "../pages/client_admins/ClientAdminDeleteUser";
import ClientAdminCubicles from "../pages/client_admins/ClientAdminCubicles";
import ClientAdminCubicleDelete from "../pages/client_admins/ClientAdminCubicleDelete";
import ClientAdminCubicleForm from "../pages/client_admins/ClientAdminCubicleForm";
import ClientAdminProcedures from "../pages/client_admins/ClientAdminProcedures";
import ClientAdminProcedureForm from "../pages/client_admins/ClientAdminProcedureForm";
import ClientAdminProcedureDelete from "../pages/client_admins/ClientAdminProcedureDelete";
import ClientAdminCubicleGroups from "../pages/client_admins/ClientAdminCubicleGroups";
import ClientAdminCubicleGroupForm from "../pages/client_admins/ClientAdminCubicleGroupForm";
import ClientAdminCubicleGroupDelete from "../pages/client_admins/ClientAdminCubicleGroupDelete";
import CubicleMap from "../pages/general/CubicleMap";
import Privacy from "../pages/general/Privacy";
import Terms from "../pages/general/Terms";
import Help from "../pages/general/Help";
import Contact from "../pages/general/Contact";
import Profile from "../pages/user/Profile";
import ClientAdminFacultyReport from "../pages/client_admins/ClientAdminFacultyReport";
import ClientAdminStudentReport from "../pages/client_admins/ClientAdminStudentReport";
import ClientAdminLoggoffTimes from "../pages/client_admins/ClientAdminLogoffTimes";
import ClientAdminLogoffTimeForm from "../pages/client_admins/ClientAdminLogoffTimeForm";
import ClientAdminLogoffTimeDelete from "../pages/client_admins/ClientAdminLogoffTimeDelete";
import NotAuthorized from "../pages/error/NotAuthorized";

const getIsLoggedIn = () => {
    return !!(localStorage.getItem('ca-user'));
};

const checkCurrentUserRole = (role) => {
    const user = JSON.parse(localStorage.getItem('ca-user'));
    return user.role === role;
};

const requireLogin = (to, from, next) => {
    const auth = to.meta.auth || false;
    if (auth) {
        if (getIsLoggedIn()) {
            next();
        }
        next.redirect(LOGIN_FORM);
    } else {
        next();
    }
};

const requireRole = (to, from, next) => {
    const role = to.meta.role || null;
    if (role) {
        if (checkCurrentUserRole(role)) {
            next();
        }
        next.redirect(PAGES_NOT_AUTHORIZED);
    } else {
        next();
    }
};

export const RouterConfig = () => {
    const userLogged = useSelector(state => state.users.userLogged);

    return (
        <GuardProvider guards={[requireLogin, requireRole]} error={NotFound}>
            <Switch>
                {/* List all public routes here */}

                <GuardedRoute path={LOGIN_FORM} exact component={Login} />
                <GuardedRoute path={PAGES_ABOUT} exact component={About} />
                <GuardedRoute path={PAGES_PRIVACY} exact component={Privacy} />
                <GuardedRoute path={PAGES_TERMS} exact component={Terms} />
                <GuardedRoute path={PAGES_HELP} exact component={Help} />
                <GuardedRoute path={PAGES_CONTACT} exact component={Contact} />

                {/*
                <GuardedRoute exact path={RESET_PASSWORD}>
                <ResetPassword />
                </GuardedRoute>

                <GuardedRoute exact path={CREATE_ACCOUNT}>
                <CreateAccount />
                </GuardedRoute>
                */}

                {/* List all private/auth routes here */}
                <GuardedRoute path={LOGOUT} exact component={Logout} meta={{ auth: true }} />
                <GuardedRoute path={USER_HOME} exact meta={{ auth: true }} >
                    {(userLogged?.role === USER_ROLE_STUDENT) && <StudentHome />}
                    {(userLogged?.role === USER_ROLE_FACULTY) && <FacultyHome />}
                    {(userLogged?.role === USER_ROLE_CLIENT_ADMIN) && <ClientAdminUsers />}
                </GuardedRoute>

                <GuardedRoute path={CUBICLES_MAP} exact component={CubicleMap} meta={{ auth: true }} />

                <GuardedRoute path={STUDENT_HOME} exact component={StudentHome} meta={{ auth: true, role: USER_ROLE_STUDENT }} />
                <GuardedRoute path={USER_PROFILE} exact component={Profile} meta={{ auth: true }} />

                <GuardedRoute path={STUDENT_MY_ACCOUNT} exact component={AccountStudent} meta={{ auth: true, role: USER_ROLE_STUDENT }} />
                <GuardedRoute path={STUDENT_REQUEST_SUBMITTED} exact component={RequestSubmitted} meta={{ auth: true, role: USER_ROLE_STUDENT }} />
                <GuardedRoute path={STUDENT_REQUEST_HELP} exact component={RequestHelp} meta={{ auth: true, role: USER_ROLE_STUDENT }} />
                <GuardedRoute path={STUDENT_CHECKOUT} exact component={Checkout} meta={{ auth: true, role: USER_ROLE_STUDENT }} />

                <GuardedRoute path={FACULTY_HOME} exact component={FacultyHome} meta={{ auth: true, role: USER_ROLE_FACULTY }} />
                <GuardedRoute path={FACULTY_MY_ACCOUNT} exact component={AccountFaculty} meta={{ auth: true, role: USER_ROLE_FACULTY }} />

                <GuardedRoute path={CLIENT_ADMIN_FACULTY_REPORT} exact component={ClientAdminFacultyReport} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_STUDENT_REPORT} exact component={ClientAdminStudentReport} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_USERS} exact component={ClientAdminUsers} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_USER} exact component={ClientAdminCreateUser} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_USER} exact component={ClientAdminEditUser} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_USER} exact component={ClientAdminDeleteUser} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_CUBICLES} exact component={ClientAdminCubicles} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_CUBICLE} exact component={ClientAdminCubicleForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_CUBICLE} exact component={ClientAdminCubicleForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_CUBICLE} exact component={ClientAdminCubicleDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_LOGOFF_TIMES} exact component={ClientAdminLoggoffTimes} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_LOGOFF_TIME} exact component={ClientAdminLogoffTimeForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_LOGOFF_TIME} exact component={ClientAdminLogoffTimeForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_LOGOFF_TIME} exact component={ClientAdminLogoffTimeDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_CUBICLE_GROUPS} exact component={ClientAdminCubicleGroups} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_CUBICLE_GROUP} exact component={ClientAdminCubicleGroupForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_CUBICLE_GROUP} exact component={ClientAdminCubicleGroupForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_CUBICLE_GROUP} exact component={ClientAdminCubicleGroupDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                <GuardedRoute path={CLIENT_ADMIN_PROCEDURES} exact component={ClientAdminProcedures} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_CREATE_PROCEDURE} exact component={ClientAdminProcedureForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_EDIT_PROCEDURE} exact component={ClientAdminProcedureForm} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />
                <GuardedRoute path={CLIENT_ADMIN_DELETE_PROCEDURE} exact component={ClientAdminProcedureDelete} meta={{ auth: true, role: USER_ROLE_CLIENT_ADMIN }} />

                { /*
                <GuardedRoute
                    exact
                    path={CUSTOMERS}
                    component={AddressBook}
                    meta={{ auth: true }}
                />
                <GuardedRoute
                    exact
                    path={PROFILE}
                    component={Profile}
                    meta={{ auth: true }}
                />
                <GuardedRoute
                    exact
                    path={EDIT_PROFILE}
                    component={EditProfile}
                    meta={{ auth: true }}
                />
                <GuardedRoute
                    exact
                    path={INVOICES}
                    component={Invoices}
                    meta={{ auth: true }}
                />
                <GuardedRoute
                    exact
                    path={ADMIN_INVOICES}
                    component={AdminInvoices}
                    meta={{ auth: true }}
                />
                <GuardedRoute
                    exact
                    path={ADMINISTRATION}
                    component={Administration}
                    meta={{ auth: true }}
                />
                <GuardedRoute
                    exact
                    path={TRACKING}
                    component={Tracking}
                    meta={{ auth: true }}
                />
                */ }

                {/* List a generic 404-Not Found route here */}
                <GuardedRoute path="*">
                    <NotFound />
                </GuardedRoute>

                <GuardedRoute path={PAGES_NOT_AUTHORIZED} >
                    <NotAuthorized />
                </GuardedRoute>

            </Switch>
        </GuardProvider>
    );
};
