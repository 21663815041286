import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {
    updateShowDropdownMobile,
    updateIndexDropdownMobile,
} from '../../redux/actions/ui.actions';
import { getBorderClassNames } from '../../utils/Navbar/getClasses';
import Cubicle from './Cubicle';

const ItemDropdown = ({ dropdownProps, index }) => {
    const dispatch = useDispatch();
    const indexDropdownMobile = useSelector((state) => state.ui.indexDropdownMobile);
    const showDropdownMobile = useSelector((state) => state.ui.showDropdownMobile);

    const classNames = getBorderClassNames(dropdownProps.isActive, index, showDropdownMobile, indexDropdownMobile);

    const handleShowDropdownMobile = (isOpen) => {
        dispatch(updateShowDropdownMobile(isOpen));
    };

    const handleIndexDropdownMobile = (index) => {
        dispatch(updateIndexDropdownMobile(index));
    };

    const getItemTypeDropdownTitle = (dropdownProps, isShowDropdownMobile) => {
        const isCubicle = dropdownProps.cubicle !== null;
        const dropdownToggleClassName = `d-after-none mx-1 px-md-3 py-2 my-1 my-md-0 ${isCubicle ? 'pt-md-0 pb-md-1 py-md-2 pl-2' : 'py-md-2'}`;
        if (isShowDropdownMobile) {
            dropdownToggleClassName.concat(' d-none d-md-block');
        }

        return (
            <DropdownToggle className={dropdownToggleClassName} caret nav>
                <div className="d-flex position-relative">
                    {isCubicle && <Cubicle {...dropdownProps.cubicle} />}
                    <span className="align-self-center w-100 ms-md-2">
                        {dropdownProps.text}
                    </span>
                    <FontAwesomeIcon className="d-md-none align-self-center fs-4" icon={faAngleRight} />
                    <FontAwesomeIcon className="d-none d-md-flex align-self-center fs-5 ms-3" icon={faAngleDown} />
                </div>
            </DropdownToggle>
        );
    };

    const getItemTypeDropdownItem = (item, index2ndLevel) => (
        <DropdownItem
            tag={RouterNavLink}
            to={item.href ? item.href : ''}
            key={index2ndLevel}
            activeClassName="dummy-active"
            disabled={item.disabled}
            className={
                'px-0 px-md-3 py-2 my-0 ' +
                (index !== 0 ? 'border-top' : '') +
                ' border-top-md-0 ' +
                (item.className ? item.className : '')
            }
            {...(item.onclick && {
                onClick: (e) => {
                    e.preventDefault();
                    item.onclick();
                },
            })}
        >
            <span className="d-inline-block py-1 py-md-0 px-md-2">
                {item.text ? item.text : ''}
            </span>
        </DropdownItem>
    );

    return (
        <UncontrolledDropdown
            inNavbar
            nav
            className={classNames}
            onClick={() => {
                handleShowDropdownMobile(true);
                handleIndexDropdownMobile(index);
            }}
            onToggle={(e, isOpen) => handleShowDropdownMobile(isOpen)}
            key={dropdownProps.text}
        >
            {getItemTypeDropdownTitle(
                dropdownProps,
                showDropdownMobile && index === indexDropdownMobile,
            )}
            <DropdownMenu
                className="w-100 rounded-0 mt-1 border-0 border-md border-md-1 border-top-md-0 bg-light bg-md-white"
                active={dropdownProps.isActive.toString()}
            >
                {dropdownProps.items.map((prop, index2ndLevel) =>
                    getItemTypeDropdownItem(prop, index2ndLevel),
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default ItemDropdown;
