import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AppBreadcrumb = ({ headText, crumbList = [] }) => {
    // crumbList is an array of objects with the following properties:
    // {
    //     label: 'text to display',
    //     linkTo: 'link to navigate to',
    // }

    const homeTargetUrl = useSelector((state) => state.ui.homeTargetUrl);

    return (
        <Breadcrumb className='mt-2 fs-7'>
            <BreadcrumbItem>
                <Link to={homeTargetUrl} className='text-body text-decoration-none'>Home</Link>
            </BreadcrumbItem>
            {crumbList.map((crumb, index) => (
                <BreadcrumbItem key={index}>
                    <Link to={crumb.linkTo} className='text-body text-decoration-none'>{crumb.label}</Link>
                </BreadcrumbItem>
            ))}
            <BreadcrumbItem active>
                <span className='text-body'>{headText}</span>
            </BreadcrumbItem>
        </Breadcrumb>
    )
};

export default AppBreadcrumb;
