import axios from 'axios';
import { API_MY_CUBICLE } from './API_CONSTANTS';

export const getMyCubicle = () => {

    return new Promise((resolve, reject) => {
        try {
            axios.get(API_MY_CUBICLE)
                .then((res) => {
                    console.log('My cubicle loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get my cubicle error: ', err);
                    reject('Get my cubicle error');
                });
        } catch (error) {
            console.error('Get my cubicle error: ', error);
            reject('Get my cubicle error');
        }
    });
};
