import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { CREATE_REQUEST_LOG, STUDENT_CANCEL_REQUEST_LOG } from '../actions/request_logs.actions';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setToast } from '../actions/ui.actions';
import * as requestLogsApi from "../../services/requestLogsService";
import { TOAST_SEVERITY_ERROR } from '../reducers/ui.reducer';
import { getMyCubicle } from '../actions/my_cubicle.actions';
import { REQUEST_STATUS_CANCELLED, REQUEST_STATUS_UI_NAMES } from '../reducers/my_cubicle.reducer';
import { getCubicleQueue } from '../actions/cubicles.actions';

/**
 * createRequestLogEffect
 */
function* createRequestLogEffect({ payload }) {
    const { request_status } = payload;
    const requestStatusDescription = REQUEST_STATUS_UI_NAMES[request_status];
    yield put(enableOverlay(`Setting status as ${requestStatusDescription} ...`));

    try {
        const requestLog = yield call(requestLogsApi.createRequestLog, payload);
        if (requestLog) {
            yield put(getCubicleQueue());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(JSON.stringify(err)));
    }
    yield put(disableOverlay());
}

/**
 * studentCancelRequestLogEffect
 */
 function* studentCancelRequestLogEffect() {
    const lastCubicleLog = yield select((state) => state.myCubicle.last_cubicle_log);
    if (lastCubicleLog) {
        yield put(enableOverlay('Canceling request ...'));
        try {
            const requestLogData = {
                cubicle_log: lastCubicleLog.id,
                request_status: REQUEST_STATUS_CANCELLED,
            }
            const requestLog = yield call(requestLogsApi.createRequestLog, requestLogData);
            if (requestLog) {
                yield put(getMyCubicle());
            }
        } catch (err) {
            console.log('error:', err);
            yield put(genericError(JSON.stringify(err)));
        }
        yield put(disableOverlay());
    } else {
        yield put(setToast({
            severity: TOAST_SEVERITY_ERROR,
            summary: 'Cubicle status Error',
            detail: 'There is not a valid cubicle status to create a request log',
            life: 8000
        }));
    }
}

export function* watchCreateRequestLog() {
    yield takeEvery(CREATE_REQUEST_LOG, createRequestLogEffect);
}

export function* watchStudentCancelRequestLog() {
    yield takeEvery(STUDENT_CANCEL_REQUEST_LOG, studentCancelRequestLogEffect);
}

function* requestLogsSaga() {
    yield all([
        fork(watchCreateRequestLog),
        fork(watchStudentCancelRequestLog),
    ]);
}

export default requestLogsSaga;
