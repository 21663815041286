import {
    CHECK_USER_REGISTRATION,
    PASSWORD_RESET,
    PASSWORD_RESET_BY_EMAIL,
    USER_UPDATE,
    USERS_API,
    API_FACULTIES_LOGGED_IN,
    API_FACULTY_LIST
} from "./API_CONSTANTS";
import axios from "axios";

export const getLoggedUser = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(USERS_API)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user logged error: ", err);
                    reject("user logged error!");
                });
        } catch (error) {
            reject("user logged error!");
        }
    });
}

export const checkUserRegistration = (email) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(USERS_API + CHECK_USER_REGISTRATION, email)
                .then((res) => {
                    console.log("user not registred: ");
                    resolve(res.status === 200);
                })
                .catch((err) => {
                    console.log("user email error: ", err);
                    reject("user email error!");
                });
        } catch (error) {
            reject("user email error!");
        }
    });
}

export const postUser = (user) => {
    const userCredentials = { username: user.username, email: user.email, password: user.password, role: user.role, cubicle_id: user.cubicle_id };
    return new Promise((resolve, reject) => {
        try {
            axios.post(USERS_API, userCredentials)
                .then((res) => {
                    console.log("user credentials created: ", res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user credentials error: ", err);
                    reject("user credentials error!");
                });
        } catch (error) {
            reject("user credentials error!");
        }
    });
}

export const updateUser = (user) => {
    return new Promise((resolve, reject) => {
        try {
            axios.put(USERS_API + USER_UPDATE, user, {
                headers: {
                    "content-type": "application/json"
                }
            })
                .then((res) => {
                    console.log("user data updated: ", res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("user data error: ", err);
                    reject("user data error!");
                });
        } catch (error) {
            reject("user data error!");
        }
    });
}


export const resetPasswordByEmail = (email) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(USERS_API + PASSWORD_RESET_BY_EMAIL, email)
                .then((res) => {
                    console.log("Sending email to reset password: ", res.data);
                    resolve(res.status);
                })
                .catch((err) => {
                    reject("Reset password error!");
                });
        } catch (error) {
            reject("Reset password error!");
        }
    });
}

export const resetPasswordCredentials = (newCredentials) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(USERS_API + PASSWORD_RESET, newCredentials)
                .then((res) => {
                    console.log("Reset password: ", res.data);
                    resolve(res.status);
                })
                .catch((err) => {
                    reject("Reset password error!");
                });
        } catch (error) {
            reject("Reset password error!");
        }
    });
}

export const retrieveFacultiesLoggedIn = (clientKey) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(API_FACULTIES_LOGGED_IN + clientKey)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("faculties logged in error: ", err);
                    reject("faculties logged in error!");
                });
        } catch (error) {
            reject("faculties logged in error!");
        }
    });
}

export const getFacultyList = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(API_FACULTY_LIST)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("faculty list error: ", err);
                    reject("faculty list error!");
                });
        } catch (error) {
            reject("faculty list error!");
        }
    });
}
