import axios from 'axios';
import { LOGIN, LOGIN_BY_HASH, TOKEN_INVALIDATE, TOKEN_LOGOUT_ALL } from './API_CONSTANTS';

export const userLogin = ({ username, password }) => {
    const credentials = { username: username, password: password };
    return new Promise((resolve, reject) => {
        try {
            axios.post(LOGIN, credentials)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Auth error: ', err);
                    reject('signin error!');
                });
        } catch (error) {
            console.error('signin error!==', error);
            reject('signin error!');
        }
    });
};

export const userLoginWithHash = ({ single_login_hash }) => {
    const credentials = { single_login_hash };
    return new Promise((resolve, reject) => {
        try {
            axios.post(LOGIN_BY_HASH, credentials)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Auth error: ', err);
                    reject('signin error!');
                });
        } catch (error) {
            console.error('signin error!==', error);
            reject('signin error!');
        }
    });
};

export const userSignOut = (refreshToken) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(TOKEN_INVALIDATE, { refresh: refreshToken })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('signout error: ', err);
                    reject('signout error!');
                });
        } catch (error) {
            console.error('signout error!==', error);
            reject('signout error!');
        }
    });
};

export const userLogoutAll = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(TOKEN_LOGOUT_ALL)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Logout all error: ', err);
                    reject('Logout all error!');
                });
        } catch (error) {
            console.error('Logout all error!==', error);
            reject('Logout all error!');
        }
    });
};
