import React, { useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import { CLIENT_ADMIN_CREATE_CUBICLE_GROUP, CLIENT_ADMIN_DELETE_CUBICLE_GROUP, CLIENT_ADMIN_EDIT_CUBICLE_GROUP } from '../../navigation/ROUTE_CONSTANTS';
import { getAdminListCubicleGroups } from "../../redux/actions/cubicle_groups.actions";
import { setNavigateTo } from "../../redux/actions/ui.actions";
import { customStyles } from '../../theme/assets/dataTableCustomStyles';

const ClientAdminCubicleGroups = () => {
    const adminCubicleGroupsList = useSelector((state) => state.adminCubicleGroups.adminCubicleGroupsList);

    const dispatch = useDispatch();

    const createCubicleGroup = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_CREATE_CUBICLE_GROUP));
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Cubicle Groups';
    });

    useEffect(() => {
        dispatch(getAdminListCubicleGroups());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Heading text',
            selector: row => row.heading_text,
            sortable: true,
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (<>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_EDIT_CUBICLE_GROUP.replace(':cubicleGroupId', row.id)} >
                    <FontAwesomeIcon icon={faPen} title="Edit" />
                </Link>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_DELETE_CUBICLE_GROUP.replace(':cubicleGroupId', row.id)} >
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Link>
            </>),
        }
    ];

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText="Cubicle Groups" />
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row className='pb-1 mb-2 mt-2'>
                <Col md={3}>
                    <h3 className='mb-4 mt-2'>Cubicle Groups</h3>
                </Col>
                <Col md={{ offset: 6, size: 3 }}>
                    <Button color={'success'} onClick={createCubicleGroup} block>
                        New cubicle group
                    </Button>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    {adminCubicleGroupsList && adminCubicleGroupsList.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            Empty list
                        </div>
                    ) : (
                        <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search cubicle group"
                            columns={columns}
                            data={adminCubicleGroupsList} >
                            <DataTable
                                columns={columns}
                                data={adminCubicleGroupsList}
                                noHeader
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </DataTableExtensions>
                    )}
                </Col >
            </Row>
        </Container>
    )
};

export default ClientAdminCubicleGroups;