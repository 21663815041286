import axios from 'axios';
import { API_REQUEST_LOGS } from './API_CONSTANTS';

// Create cublicle log
export const createRequestLog = (requestLog) => {
    const url = API_REQUEST_LOGS;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, requestLog)
                .then((res) => {
                    console.log('Request log created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create request log error: ', err);
                    reject('Create request log error');
                });
        } catch (error) {
            console.error('Create request log error: ', error);
            reject('Create request log error');
        }
    });
}
