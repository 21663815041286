import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_CLIENTS } from './API_CONSTANTS';

export const getClients = (paginationParams) => {
    paginationParams = paginationParams ? paginationParams : {};

    const url = API_CLIENTS + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Clients loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get clients error: ', err);
                    reject('Get clients error');
                });
        } catch (error) {
            console.error('Get clients error: ', error);
            reject('Get clients error');
        }
    });
};

export const getClient = (clientId) => {
    let url = API_CLIENTS + '/' + clientId;
    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Client loaded: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Get client error: ", err);
                    reject("Get client error");
                });
        } catch (error) {
            console.error("Get client error: ", error);
            reject("Get client error");
        }
    });
};

export const createClient = (client) => {
    const url = API_CLIENTS;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, client)
                .then((res) => {
                    console.log('Client created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create client error: ', err);
                    reject('Create client error');
                });
        } catch (error) {
            console.error('Create client error: ', error);
            reject('Create client error');
        }
    });
}

export const updateClient = (client) => {
    const url = API_CLIENTS + '/' + client.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, client)
                .then((res) => {
                    console.log('Client updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update client error: ', err);
                    reject('Update client error');
                });
        } catch (error) {
            console.error('Update client error: ', error);
            reject('Update client error');
        }
    });
}

export const removeClient = (client) => {
    const url = API_CLIENTS + '/' + client.id;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Client removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove client error: ', err);
                    reject('Remove client error');
                });
        } catch (error) {
            console.error('Remove client error: ', error);
            reject('Remove client error');
        }
    });
}
