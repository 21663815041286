import { useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByAdmin } from '../../services/userAdminService';
import { updateUserByAdmin } from '../../redux/actions/admin.users.actions';
import { getUnassignedCubicles } from '../../redux/actions/cubicles.actions';
import { USER_ROLE_UI_NAMES } from '../../redux/reducers/auth.reducer';

function EditUserForm() {

    const dispatch = useDispatch();
    const { userId } = useParams();
    const [user, setUser] = useState('');
    const [isStudent, setIsStudent] = useState(false);
    const [cubicle, setCubicle] = useState('');
    const [currentAssignedCubicle, setCurrentAssignedCubicle] = useState(null);

    const unassignedCubicles = useSelector(state => state.cubicles.unassignedCubicles);
    const cubicleMapIdToName = useSelector((state) => state.cubicles.mapIdToName);

    useEffect(() => {
        getUserByAdmin(userId).then((res) => {
            setUser(res);
            if (res.role === "student") {
                setIsStudent(true);
                setCubicle(res.assigned_cubicle_id.toString());
                setCurrentAssignedCubicle({
                    id: res.assigned_cubicle_id,
                    name: cubicleMapIdToName[res.assigned_cubicle_id] || 'New Cubicle, please refresh page',
                });
            }
        })

        dispatch(getUnassignedCubicles());
    }, [cubicleMapIdToName, userId, dispatch]);

    function onChangeRole(value, setFieldValue) {
        if (value === "student") {
            setIsStudent(true)
        } else {
            setIsStudent(false)
            setFieldValue('assigned_cubicle_id', '')
        }
    }

    const initialValues_user = {
        username: user.username || '',
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || '',
        new_password: '',
        role: user.role || '',
        assigned_cubicle_id: cubicle
    }

    return (
        <Row>
            <Col>
                <div className='vstack col-md-4 mx-auto'>
                    <h4 className='pt-2 pb-3 text-center'>Edit User</h4>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues_user}
                        validationSchema={Yup.object({
                            username: Yup.string()
                                .min(1, 'Username must be at least 1 characters length.')
                                .max(150, 'Username must be smaller than 150 characters length.')
                                .required('Please enter your username.'),
                            first_name: Yup.string()
                                .max(150, 'First name must be smaller than 150 characters length.'),
                            last_name: Yup.string()
                                .max(150, 'Last name must be smaller than 150 characters length.'),
                            email: Yup.string()
                                .email('Invalid email address.')
                                .max(254, 'Email must be smaller than 254 characters length.'),
                            new_password: Yup.string()
                                .min(8, 'Password must be at least 8 characters length')
                                .max(128, 'Password must be smaller than 128 characters length.'),
                            role: Yup.string()
                                .required('Please select the role.'),
                            assigned_cubicle_id:
                                Yup.string()
                                    .when('role', {
                                        is: 'student',
                                        then: Yup.string()
                                            .required('Please select the cubicle.'),
                                    })
                        })}
                        onSubmit={(values) => {
                            let newEditUser = {
                                id: user.id,
                                username: values.username,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                is_active: true,
                                role: values.role,
                                email: values.email
                            }

                            if (values.role === 'student') { newEditUser.assigned_cubicle_id = Number(values.assigned_cubicle_id) }
                            if (values.new_password) {
                                newEditUser.password = values.new_password;
                            }
                            dispatch(updateUserByAdmin(newEditUser));
                        }}
                    >
                        {({
                            handleChange,
                            setFieldValue
                        }) => {
                            return (
                                <Form>
                                    <FormGroup>
                                        <Field type="text" name="username" placeholder="Username" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="username" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field type="text" name="first_name" placeholder="First Name" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="first_name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field type="text" name="last_name" placeholder="Last Name" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="last_name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field type="email" name="email" placeholder="Email" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="email" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field type="password" name="new_password" placeholder="New password" autoComplete="new-password" as={Input} />
                                        <ErrorMessage component="div" className="text-danger" name="new_password" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field id="role" name="role" as="select"
                                            placeholder="Role"
                                            className="form-select"
                                            onChange={(e) => {
                                                handleChange(e);
                                                onChangeRole(e.currentTarget.value, setFieldValue)
                                            }}
                                        >
                                            <option value="">Select Role</option>
                                            {Object.entries(USER_ROLE_UI_NAMES).map((role) =>
                                                <option value={role[0]}
                                                    key={role[0]}>{role[1]}</option>)
                                            }
                                        </Field>
                                        <ErrorMessage component="div" className="text-danger" name="role" />
                                    </FormGroup>
                                    {isStudent &&
                                        <FormGroup>
                                            <Field id="assigned_cubicle_id" name="assigned_cubicle_id" as="select"
                                                placeholder="Cubicle"
                                                className="form-select"
                                                onChange={handleChange}
                                            >
                                                <option value=''>Select Cubicle</option>
                                                {currentAssignedCubicle &&
                                                    <option value={currentAssignedCubicle.id}
                                                        key={currentAssignedCubicle.id}>{currentAssignedCubicle.name}</option>
                                                }
                                                {unassignedCubicles.map(cubicleOption =>
                                                    <option value={cubicleOption.id}
                                                        key={cubicleOption.id}>{cubicleOption.name}</option>)
                                                }
                                            </Field>
                                            <ErrorMessage component="div" className="text-danger" name="assigned_cubicle_id" />
                                            <small className="form-text text-muted">
                                                Only unassigned cubicles are eligible to select.
                                            </small>
                                        </FormGroup>
                                    }
                                    <div className='text-center'>
                                        <Button color='success' className='mt-2 px-5' type='submit'>
                                            <span className='d-inline-block fs-5 px-3'>Save User</span>
                                        </Button>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </Col>
        </Row >
    )
}

export default EditUserForm