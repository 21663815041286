import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_CLIENT,
    DELETE_CLIENT,
    UPDATE_CLIENT,
    GET_ADMIN_LIST_CLIENTS,
    GET_LIST_CLIENTS,
    getAdminListClientsSuccess,
    getClientsSuccess,
    getAdminListClients,
} from '../actions/clients.actions';
import { createClient, getClients, removeClient, updateClient } from '../../services/clientsService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay } from '../actions/ui.actions';

/**
 * getListClientsEffect
 */
function* getListClientsEffect() {
    try {
        const clientsList = yield call(getClients);
        yield put(getClientsSuccess(clientsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getAdminListClientsEffect
 */
function* getAdminListClientsEffect() {
    yield put(enableOverlay('Loading clients ...'));
    try {
        const clientsList = yield call(getClients);
        yield put(getAdminListClientsSuccess(clientsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createClientEffect
 */
function* createClientEffect() {
    try {
        yield put(enableOverlay('Creating client ...'));
        const client = yield call(createClient);
        if (client) {
            yield put(getAdminListClients());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * updateClientEffect
 */
function* updateClientEffect() {
    try {
        yield put(enableOverlay('Updating client ...'));
        const client = yield call(updateClient);
        if (client) {
            yield put(getAdminListClients());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * deleteClientEffect
 */
 function* deleteClientEffect() {
    try {
        yield put(enableOverlay('Deleting client ...'));
        const client = yield call(removeClient);
        if (client) {
            yield put(getAdminListClients());
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

export function* watchGetListClients() {
    yield takeEvery(GET_LIST_CLIENTS, getListClientsEffect);
}

export function* watchGetAdminListClients() {
    yield takeEvery(GET_ADMIN_LIST_CLIENTS, getAdminListClientsEffect);
}

export function* watchCreateClient() {
    yield takeEvery(CREATE_CLIENT, createClientEffect);
}

export function* watchUpdateClient() {
    yield takeEvery(UPDATE_CLIENT, updateClientEffect);
}

export function* watchDeleteClient() {
    yield takeEvery(DELETE_CLIENT, deleteClientEffect);
}

function* clientsSaga() {
    yield all([
        fork(watchGetListClients),
        fork(watchGetAdminListClients),
        fork(watchCreateClient),
        fork(watchUpdateClient),
        fork(watchDeleteClient),
    ]);
}

export default clientsSaga;
