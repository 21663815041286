import {createAction} from "@reduxjs/toolkit";

// user action types
export const GET_USER = '[User] Getting user info...';
export const GET_USER_SUCCESS = '[User] user info recover with success!';
export const UPDATE_USER_SUCCESS = '[Users] user updated!';
export const PATCH_USER_SUCCESS = '[Users] user patched!';
export const RESET_USER_STATE = '[Users] Reset user state';
export const CREATE_USER = '[Users] user creating...!';
export const CREATE_USER_SUCCESS = '[Users] user create with success!';
export const UPDATE_USER_PROFILE = '[Users] updating user profile...';
export const UPDATE_USER_PROFILE_SUCCESS = '[Users] updating user profile success!';
export const RESET_PASSWORD = '[User] sending email to reset password';
export const RESET_PASSWORD_SUCCESS = '[User] Email to reset password send!';
export const RESET_PASSWORD_CONFIRMATION = '[User] resetting password...';
export const RESET_PASSWORD_CONFIRMATION_SUCCESS = '[User] resetting password success!'
export const SET_WEBSOCKET_URL = '[User] Setting websocket url';

export const GET_FACULTY_LIST = '[User] Getting faculty list ...';
export const GET_FACULTY_LIST_SUCCESS = '[User] Getting faculty list success!';

export const RETRIEVE_FACULTIES_LOGGED_IN = '[Faculties Logged In] Retrieving faculties logged in ...!';
export const RETRIEVE_FACULTIES_LOGGED_IN_SUCCESS = '[Faculties Logged In] faculties logged in retrieved with success!';

// user actions
export const getUser = createAction(GET_USER);
export const getUserSuccess = createAction(GET_USER_SUCCESS);
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const patchUserSuccess = createAction(PATCH_USER_SUCCESS);
export const resetUserState = createAction(RESET_USER_STATE);
export const createUser = (user, history) => ({
    type: CREATE_USER,
    payload: {user: user, history: history}
});
export const createUserSuccess = createAction(CREATE_USER_SUCCESS);
export const updateUserProfile = (user, history, auth) => ({
    type: UPDATE_USER_PROFILE,
    payload: {user: user, history: history, auth: auth}
});
export const updateUserProfileSuccess = (result) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: result
});

export const resetPassword = (email, history) => ({
    type: RESET_PASSWORD,
    payload: {email: email, history: history}
});

export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS);

export const resetPasswordConfirmation = (newCredentials, history) => ({
    type: RESET_PASSWORD_CONFIRMATION,
    payload: {newCredentials: newCredentials, history: history}
})

export const resetPasswordConfirmationSuccess = createAction(RESET_PASSWORD_CONFIRMATION_SUCCESS);

export const getFacultyList = createAction(GET_FACULTY_LIST);
export const getFacultyListSuccess = createAction(GET_FACULTY_LIST_SUCCESS);

export const retrieveFacultiesLoggedIn = createAction(RETRIEVE_FACULTIES_LOGGED_IN);
export const retrieveFacultiesLoggedInSuccess = createAction(RETRIEVE_FACULTIES_LOGGED_IN_SUCCESS);

export const setWebsocketUrl = createAction(SET_WEBSOCKET_URL);
