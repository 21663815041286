import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "../actions/auth.actions";

const initialAuthState = { accessToken: null, refreshToken: null };

export const USER_ROLE_STUDENT = "student";
export const USER_ROLE_FACULTY = "faculty";
export const USER_ROLE_CLIENT_ADMIN = "client_admin";

export const USER_ROLE_UI_NAMES = {
    [USER_ROLE_STUDENT]: 'Student',
    [USER_ROLE_FACULTY]: 'Faculty',
    [USER_ROLE_CLIENT_ADMIN]: 'Client Admin',
};

export const AuthReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.access,
                refreshToken: action.payload?.refresh
            };
        case LOGOUT_SUCCESS:
            return initialAuthState;
        default:
            return state;
    }
}