import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'reactstrap';
import { Input } from 'reactstrap';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faUserShield } from '@fortawesome/free-solid-svg-icons'
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersListByAdmin } from '../../redux/actions/admin.users.actions';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link } from "react-router-dom";
import { USER_ROLE_STUDENT, USER_ROLE_UI_NAMES } from "../../redux/reducers/auth.reducer";
import { CLIENT_ADMIN_CREATE_USER, CLIENT_ADMIN_DELETE_USER, CLIENT_ADMIN_EDIT_USER } from '../../navigation/ROUTE_CONSTANTS';
import { getCubicles } from "../../redux/actions/cubicles.actions";
import { setNavigateTo } from "../../redux/actions/ui.actions";
import SingleLoginButtonModal from "../../components/client_admins/SingleLoginButtonModal";
import { customStyles } from '../../theme/assets/dataTableCustomStyles';

const ClientAdminUsers = () => {
    const usersList = useSelector((state) => state.usersByAdmin.usersList);
    const cubicleMapIdToName = useSelector((state) => state.cubicles.mapIdToName);
    const [singleLoginButtonModalOpen, setSingleLoginButtonModalOpen] = useState(false);
    const [singleLoginButtonUserData, setSingleLoginButtonUserData] = useState(null);

    const data = usersList

    const dispatch = useDispatch();

    const createUser = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_CREATE_USER));
    }

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Users';
    });

    useEffect(() => {
        loadUsers();
        dispatch(getCubicles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadUsers = () => {
        dispatch(getUsersListByAdmin());
    };

    const columns = [
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => USER_ROLE_UI_NAMES[row.role] || '',
            sortable: true,
            center: true,
            className: 'text-body'
        },
        {
            name: 'Logged in',
            center: true,
            cell: (row) => {
                if (row.is_logged_in) {
                    return <Input type='checkbox' defaultChecked disabled />
                } else {
                    return <Input type='checkbox' disabled />
                }
            }
        },
        {
            name: 'Last login',
            sortable: true,
            cell: (row) => {
                if (row.last_login) {
                    return new Date(row.last_login).toLocaleString('en-US', {dateStyle: 'short', timeStyle: 'short'}).replace(',', '');
                } else {
                    return null
                }
            }
        },
        {
            name: 'Cubicle',
            selector: row => cubicleMapIdToName[row.assigned_cubicle_id] || '',
            sortable: true,
            center: true
        },
        {
            name: 'Actions',
            center: true,
            cell: (row) => (<>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_EDIT_USER.replace(':userId', row.id)} >
                    <FontAwesomeIcon icon={faPen} title="Edit" />
                </Link>
                <Button disabled={row.role !== USER_ROLE_STUDENT} className='text-dark btn btn-link btn-light' onClick={(e) => {
                    setSingleLoginButtonUserData(row);
                    setSingleLoginButtonModalOpen(true);
                }}>
                    <FontAwesomeIcon icon={faUserShield} title="Single Login Button" />
                </Button>
                <Link className='text-dark btn btn-link btn-light' to={CLIENT_ADMIN_DELETE_USER.replace(':userId', row.id)} >
                    <FontAwesomeIcon icon={faTrash} title="Delete" />
                </Link>
            </>),
        }
    ];

    const tableData = {
        columns,
        data
    };

    return (
        <Container className="min-vh-72">
            <Row>
                <Col>
                    <AppBreadcrumb headText="Users" />
                </Col>
            </Row>
            <Row>
                <Col>
                </Col>
            </Row>
            <Row className='pb-1 mb-2 mt-2'>
                <Col md={3}>
                    <h3 className='mb-4 mt-2'>Users</h3>
                </Col>
                <Col md={{ offset: 6, size: 3 }}>
                    <Button color={'success'} onClick={createUser} block>
                        Create new user
                    </Button>
                </Col>
            </Row>
            <Row className='mb-5'>
                <Col>
                    {usersList && usersList.length === 0 ? (
                        <div className="alert alert-primary mt-2">
                            There are no users available, please add one.
                        </div>
                    ) : (
                        <DataTableExtensions filter={true} export={false} print={false} filterPlaceholder="Search User"	{...tableData}>
                            <DataTable
                                columns={columns}
                                data={data}
                                noHeader
                                pagination
                                highlightOnHover
                                customStyles={customStyles}
                            />
                        </DataTableExtensions>
                    )}
                </Col >
            </Row>
            <SingleLoginButtonModal modalOpen={singleLoginButtonModalOpen}
                setModalClosed={(e) => setSingleLoginButtonModalOpen(false)}
                userData={singleLoginButtonUserData}
            />
        </Container>
    )
};

export default ClientAdminUsers;