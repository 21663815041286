import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import CubicleGrid, { CUBICLE_GRID_TYPE_MY_LIST, CUBICLE_GRID_TYPE_OCCUPIED, CUBICLE_GRID_TYPE_SPECIAL_REQUEST, CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST } from '../../components/faculties/CubicleGrid';
import { getCubicleQueue } from '../../redux/actions/cubicles.actions';

const FacultyHome = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCubicleQueue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
    <Container className='min-vh-72'>
        <Row className='mt-4 pt-2'>
            <Col>
                <h3 className='mt-1'>Cubicles needing assistance</h3>
            </Col>
        </Row>

        <CubicleGrid gridType={CUBICLE_GRID_TYPE_MY_LIST} />

        <CubicleGrid gridType={CUBICLE_GRID_TYPE_SPECIAL_REQUEST} />

        <CubicleGrid gridType={CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST} />

        <CubicleGrid gridType={CUBICLE_GRID_TYPE_OCCUPIED} />

    </Container>
    );
};

export default FacultyHome;