export const getBorderClassNames = (isActive, elementIndex, showDropdownMobile, indexDropdownMobile) => {
    const classNames = [
        'align-self-md-end', 'ms-md-3',
        'border-bottom-0', 'border-bottom-md',
        'border-1', 'border-md-3'
    ];

    if (isActive) {
        classNames.push('border-md-dark');
    } else {
        classNames.push('border-md-white');
    }

    if (elementIndex !== 0) {
        classNames.push('border-top border-top-md-0');
    }

    if (showDropdownMobile) {
        if (elementIndex === indexDropdownMobile) {
            classNames.push('border-top-0');
        } else {
            classNames.push('d-none d-md-block');
        }
    }

    return classNames.join(' ');
}
