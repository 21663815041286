import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { useParams } from 'react-router-dom';
import { getUserByAdmin } from '../../services/userAdminService';
import { removeUserByAdmin } from '../../redux/actions/admin.users.actions';
import { useDispatch } from 'react-redux';
import { USER_ROLE_UI_NAMES } from "../../redux/reducers/auth.reducer";
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CLIENT_ADMIN_USERS, USER_HOME } from '../../navigation/ROUTE_CONSTANTS';

function ClientAdminDeleteUser() {
    const dispatch = useDispatch();

    const { userId } = useParams();

    const [user, setUser] = useState('');

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Delete user ';
    });

    useEffect(() => {
        getUserByAdmin(userId).then((res) => setUser(res))
    }, [userId]);

    const handleCancel = () => {
        dispatch(setNavigateTo(USER_HOME));
    }

    const deleteUser = () => {
        dispatch(removeUserByAdmin(user))
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Delete User" crumbList={[{ linkTo: CLIENT_ADMIN_USERS, label: 'Users' }]} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                        <h4 className='pt-2 pb-3 text-center'>Delete User</h4>
                        <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to remove this user?</h5>
                        <p>Username: <strong>{user.username}</strong></p>
                        <p>First name:<strong>{user.first_name}</strong></p>
                        <p>Last name: <strong>{user.last_name}</strong></p>
                        <p>Role: <strong>{USER_ROLE_UI_NAMES[user.role] || ''}</strong></p>
                    </div>
                </Col>
            </Row>
            <Row>
                <p className="mt-4 mb-4 text-center">
                    <Button color={'success'} className=" me-2" onClick={deleteUser}>
                        Delete User
                    </Button>
                    <Button color={'secondary'} onClick={handleCancel} >
                        Cancel
                    </Button>
                </p>
            </Row>
        </Container>
    )
}

export default ClientAdminDeleteUser