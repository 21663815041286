import { all } from 'redux-saga/effects';
import adminInvoicesSagas from './effects/admin.invoices.saga';
import adminSettingsSagas from './effects/admin.settings.saga';
import authSaga from './effects/auth.saga';
import clientsSaga from './effects/clients.saga';
import cubicleGroupsSaga from './effects/cubicle_groups.saga';
import cubicleLogsSaga from './effects/cubicle_logs.saga';
import cubiclesSaga from './effects/cubicles.saga';
import invoicesSagas from './effects/invoices.saga';
import layoutSaga from './effects/layout.saga';
import logoutTimeSaga from './effects/logoff_times.saga';
import configurationsSaga from './effects/configurations.saga';
import myCubicleSaga from './effects/my_cubicle.saga';
import proceduresSaga from './effects/procedures.saga';
import requestLogsSaga from './effects/request_logs.saga';
import usersByAdminSaga from './effects/admin.users.saga';
import usersSaga from './effects/users.saga';

export default function* appSaga() {
    yield all([
        adminInvoicesSagas(),
        adminSettingsSagas(),
        authSaga(),
        clientsSaga(),
        cubicleGroupsSaga(),
        cubicleLogsSaga(),
        cubiclesSaga(),
        invoicesSagas(),
        layoutSaga(),
        logoutTimeSaga(),
        configurationsSaga(),
        myCubicleSaga(),
        proceduresSaga(),
        requestLogsSaga(),
        usersByAdminSaga(),
        usersSaga(),
    ]);
}
