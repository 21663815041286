import { Container, Row, Col } from 'reactstrap';
import { Form, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_HELP, CUBICLE_STATUS_OCCUPIED, CUBICLE_STATUS_SPECIAL_REQUEST, CUBICLE_STATUS_START_CHECK, CUBICLE_STATUS_UNOCCUPIED } from '../../redux/reducers/my_cubicle.reducer';
import { createCubicleLog, setOccupiedCubicleLog } from '../../redux/actions/cubicle_logs.actions';
import { STUDENT_CHECKOUT, STUDENT_REQUEST_HELP, STUDENT_REQUEST_SUBMITTED } from '../../navigation/ROUTE_CONSTANTS';
import SpecialRequestModal from '../../components/student/SpecialRequestModal';

const StudentHome = () => {
    const dispatch = useDispatch();
    const myCubicleStatus = useSelector(state => state.myCubicle.cubicle_status);
    const myCubicleId = useSelector(state => state.myCubicle.id);
    const [specialRequestModalOpen, setSpecialRequestModalOpen] = useState(false);

    useEffect(() => {
        if (myCubicleStatus) {
            switch (myCubicleStatus) {
                case CUBICLE_STATUS_OCCUPIED:
                    // Stay on the same page
                    break;
                case CUBICLE_STATUS_UNOCCUPIED:
                    dispatch(setOccupiedCubicleLog(myCubicleId));
                    // Stay on the same page
                    break;
                case CUBICLE_STATUS_START_CHECK:
                    dispatch(setNavigateTo(STUDENT_REQUEST_SUBMITTED));
                    break;
                case CUBICLE_STATUS_HELP:
                case CUBICLE_STATUS_SPECIAL_REQUEST:
                    dispatch(setNavigateTo(STUDENT_REQUEST_HELP));
                    break;
                case CUBICLE_STATUS_CHECKOUT:
                    dispatch(setNavigateTo(STUDENT_CHECKOUT));
                    break;
                default:
                    console.error('Not handled cubicle status');
            }
        }
    }, [myCubicleStatus, myCubicleId, dispatch]);

    const handleRequest = (e, requestStatus) => {
        e.preventDefault();
        const cubicleLogData = {
            cubicle_status: requestStatus,
        };
        dispatch(createCubicleLog(cubicleLogData));
    };

    const handleCheckout = (e) => {
        e.preventDefault();
        dispatch(setNavigateTo(STUDENT_CHECKOUT));
    };

    const handleSpecialRequest = (submitedValues) => {
        const cubicleLogData = {
            cubicle_status: CUBICLE_STATUS_SPECIAL_REQUEST,
            faculty_requested: submitedValues.faculty_requested,
        };
        dispatch(createCubicleLog(cubicleLogData));
    };

    return (
        <Container className='mt-5 mb-5 px-4 px-md-auto'>
            <Row>
                <Col>
                    <Form>
                        <div className='vstack gap-3 col-md-4 mx-auto'>
                            <div className='text-center'>When you are ready, select from the following options...</div>
                            <Button onClick={(e) => handleRequest(e, CUBICLE_STATUS_START_CHECK)} color='success' size='lg' type='submit'>Start check</Button>
                            <Button onClick={(e) => handleCheckout(e)} color='primary' size='lg' type='submit'>Treatment complete</Button>
                            <Button onClick={(e) => { e.preventDefault(); setSpecialRequestModalOpen(true); }} color='success' outline size='lg' type='submit'>Specific request</Button>
                            <Button onClick={(e) => handleRequest(e, CUBICLE_STATUS_HELP)} color='warning-orange' outline size='lg' type='submit'>Help</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
            <SpecialRequestModal modalOpen={specialRequestModalOpen}
                setModalClosed={(e) => setSpecialRequestModalOpen(false)}
                modalSubmit={handleSpecialRequest}
            />
        </Container>
    );
};

export default StudentHome;