import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';

const Footer = ({ legend, items }) => (
    <footer className='bg-light pt-4 pb-5 border-top border-2'>
        <Container className='pb-4 px-4 px-md-auto'>
            <Row>
                <Col>
                    <div className='d-flex flex-column flex-md-row'>
                        <Nav className='flex-column flex-md-row order-0 order-md-1'>
                            {items.map((item, index) => (
                                <Fragment key={index}>
                                    <NavItem>
                                        <NavLink to={item.href} tag={Link} className='px-0 px-md-2 mx-0 mx-md-1 py-1 py-md-0 mb-2 mb-md-0 text-helvetica fs-7 text-black-50' >
                                            {item.text}
                                        </NavLink>
                                    </NavItem>
                                    {index + 1 < items.length ? <div className='vr border-white border-top border-bottom border-3 d-none d-md-block' /> : null}
                                </Fragment>
                            ))}
                        </Nav>
                        <div className='text-helvetica fs-7 text-black-50 mt-5 mt-md-0 order-1 order-md-0'>{legend}</div>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;