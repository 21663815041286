import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_HELP, CUBICLE_STATUS_SPECIAL_REQUEST, CUBICLE_STATUS_START_CHECK, CUBICLE_STATUS_UNOCCUPIED, REQUEST_STATUS_ON_QUEUE } from '../../redux/reducers/my_cubicle.reducer';
import Cubicle, { CUBICLE_VARIANT_FACULTY_HOME } from '../../theme/components/Cubicle';
import { cubiclesSortedByName } from '../../utils';

export const CUBICLE_GRID_TYPE_MY_LIST = 'my_list';
export const CUBICLE_GRID_TYPE_SPECIAL_REQUEST = 'special_request';
export const CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST = 'assistance_request';
export const CUBICLE_GRID_TYPE_OCCUPIED = 'occupied';

// Head title map
const headTitleMap = {
    [CUBICLE_GRID_TYPE_MY_LIST]: 'My cubicle list',
    [CUBICLE_GRID_TYPE_SPECIAL_REQUEST]: 'Special assistance requested',
    [CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST]: 'Assistance requested',
    [CUBICLE_GRID_TYPE_OCCUPIED]: 'Occupied cubicles',
}

// Head subtitle map
const headSubtitleMap = {
    [CUBICLE_GRID_TYPE_MY_LIST]: 'When completed, select to clear the cubicle from your list',
    [CUBICLE_GRID_TYPE_SPECIAL_REQUEST]: null,
    [CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST]: null,
    [CUBICLE_GRID_TYPE_OCCUPIED]: null,
}

const CubicleGrid = ({ gridType }) => {
    const headTitle = headTitleMap[gridType];
    const headSubTitle = headSubtitleMap[gridType];

    const cubicleQueue = useSelector(state => state.cubicleQueue);
    const userLoggedUserName = useSelector(state => state.users.userLogged.username);
    const [gridCubicles, setGridCubicles] = useState([]);

    useEffect(() => {
        let gridCubicles = [];
        if (cubicleQueue.length > 0) {
            gridCubicles = cubicleQueue.filter(cubicle => {
                let isCubicleInThisGrid = false;

                if (gridType === CUBICLE_GRID_TYPE_OCCUPIED) {
                    isCubicleInThisGrid = (cubicle.cubicle_status !== CUBICLE_STATUS_UNOCCUPIED && cubicle.is_enabled);

                } else {
                    if (cubicle.underway_faculty === userLoggedUserName) {
                        isCubicleInThisGrid = (gridType === CUBICLE_GRID_TYPE_MY_LIST);

                    } else if (
                        cubicle.cubicle_status === CUBICLE_STATUS_SPECIAL_REQUEST &&
                        cubicle.request_status === REQUEST_STATUS_ON_QUEUE
                    ) {
                        isCubicleInThisGrid = (gridType === CUBICLE_GRID_TYPE_SPECIAL_REQUEST);

                    } else if (
                        [CUBICLE_STATUS_START_CHECK, CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_HELP].includes(cubicle.cubicle_status) &&
                        cubicle.request_status === REQUEST_STATUS_ON_QUEUE
                    ) {
                        isCubicleInThisGrid = (gridType === CUBICLE_GRID_TYPE_ASSISTANCE_REQUEST);
                    }
                }

                return isCubicleInThisGrid;
            });
        }

        if (gridType === CUBICLE_GRID_TYPE_OCCUPIED) {
            gridCubicles.sort(cubiclesSortedByName);
        }

        setGridCubicles(gridCubicles);
    }, [cubicleQueue, gridType, userLoggedUserName]);

    return (
        <Fragment>
            <Row className='mt-3 border-bottom'>
                <Col>
                    <h5 className='lh-base mt-2 mb-2'>{headTitle}</h5>
                </Col>
                {headSubTitle &&
                    <Col className='d-none d-md-block'>
                        <p className='lh-lg fs-7 mb- text-end'>{headSubTitle}</p>
                    </Col>
                }
            </Row>
            <Row className='mt-3 mb-3 pb-1'>
                {gridCubicles.map(cubicle => (
                    <Col key={cubicle.id} xs={3} md={1}>
                        <Cubicle cubicleData={cubicle} variant={CUBICLE_VARIANT_FACULTY_HOME} gridType={gridType} />
                    </Col>
                ))}
            </Row>
        </Fragment>
    )
};

export default CubicleGrid;