import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_LOGOFF_TIMES } from './API_CONSTANTS';

export const getLogoffTimes = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 999,
    };

    const url = API_LOGOFF_TIMES + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Logoff Times loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get logoff times error: ', err);
                    reject('Get logoff times error');
                });
        } catch (error) {
            console.error('Get logoff times error: ', error);
            reject('Get logoff times error');
        }
    });
};

export const getOneLogoffTime = (logoffTimeId) => {
    const url = API_LOGOFF_TIMES + '/' + logoffTimeId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Logoff Time loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one logoff time error: ', err);
                    reject('Get one logoff time error');
                });
        } catch (error) {
            console.error('Get one logoff time error: ', error);
            reject('Get one logoff time error');
        }
    });
}

export const createLogoffTime = (logoffTime) => {
    const url = API_LOGOFF_TIMES;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, logoffTime)
                .then((res) => {
                    console.log('Logoff Time created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create logoff time error: ', err);
                    reject('Create logoff time error');
                });
        } catch (error) {
            console.error('Create logoff time error: ', error);
            reject('Create logoff time error');
        }
    });
}

export const updateLogoffTime = (logoffTime) => {
    const url = API_LOGOFF_TIMES + '/' + logoffTime.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, logoffTime)
                .then((res) => {
                    console.log('Logoff Time updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update logoff time error: ', err);
                    reject('Update logoff time error');
                });
        } catch (error) {
            console.error('Update logoff time error: ', error);
            reject('Update logoff time error');
        }
    });
}

export const removeLogoffTime = (logoffTimeId) => {
    const url = API_LOGOFF_TIMES + '/' + logoffTimeId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Logoff Time removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove logoff time error: ', err);
                    reject('Remove logoff time error');
                });
        } catch (error) {
            console.error('Remove logoff time error: ', error);
            reject('Remove logoff time error');
        }
    });
}
