import { GET_CUBICLE_QUEUE_SUCCESS, GET_LIST_CUBICLES_SUCCESS, GET_ADMIN_LIST_CUBICLES_SUCCESS, GET_LIST_UNASSIGNED_CUBICLES_SUCCESS } from "../actions/cubicles.actions";

export const CUBICLE_AVAILABLE_COLORS = [
    'success',
    'primary',
    'secondary',
    'info',
    'warning',
    'danger',
    'dark',
];

const initialCubiclesState = {
    cubiclesList: [],
    next: null,
    previous: null,
    count: 0,
    mapIdToName: {},
    unassignedCubicles: [],
};

export const CubiclesReducer = (state = initialCubiclesState, action) => {
    switch (action.type) {
        case GET_LIST_CUBICLES_SUCCESS:
            let mapIdToName = {};
            action.payload.results.forEach(cubicle => {
                mapIdToName[cubicle.id] = cubicle.name;
            });

            return {
                ...state,
                cubiclesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
                mapIdToName,
            };
        case GET_LIST_UNASSIGNED_CUBICLES_SUCCESS:
            return {
                ...state,
                unassignedCubicles: action.payload,
            };
        default:
            return state;
    }
}

const initialAdminsCubicleState = {
    cubiclesAdminList: [],
    next: null,
    previous: null,
    count: 0,
};

export const CubiclesAdminReducer = (state = initialAdminsCubicleState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_CUBICLES_SUCCESS:
            return {
                ...state,
                cubiclesAdminList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            };
        default:
            return state;
    }
}

const initialCubicleQueueState = [];

export const CubicleQueueReducer = (state = initialCubicleQueueState, action) => {
    switch (action.type) {
        case GET_CUBICLE_QUEUE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
