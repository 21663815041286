import { Container, Row, Col } from 'reactstrap';

const About = () => (
    <Container className='mb-5 px-4 px-md-auto min-vh-60'>
        <Row>
            <Col md={12}>
                <h3 className='mt-5 mb-4 pt-2 pb-2'>About</h3>
            </Col>
            <Col md={10}>
                <p className='mb-4'>UMCA (University Medical Clinic App) is a next-generation reference web application, which offers specifically designed efficiency features to provide university faculty and students a better overall clinic experience. Often these types of clinic sessions can involve hundreds of students, working on various floors of a building or in different locations, who require frequent assistance and reviews of their training sessions' work with minimal notice. Students use this application and its innovative scheduling system to work independently meaning fewer faculty are required during training sessions, and fewer staff in general need to be hired to handle peak student demands.</p>
                <p>UMCA is also an important aspect of COVID safety protocols. For example, managing remote clinic sessions through UMCA helped reduce student-to-student and faculty-to-student contact keeping educational work progressing, while staying safe during the height of the COVID pandemic.</p>
            </Col>
        </Row>
    </Container>
);

export default About;