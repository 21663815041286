import { GET_ADMIN_LIST_CUBICLE_GROUPS_SUCCESS, GET_LIST_CUBICLE_GROUPS_SUCCESS } from "../actions/cubicle_groups.actions";

const initialCubicleGroupsState = {
    cubicleGroupsList: [],
    next: null,
    previous: null,
    count: 0,
    mapIdToName: {},
};

export const CubicleGroupsReducer = (state = initialCubicleGroupsState, action) => {
    switch (action.type) {
        case GET_LIST_CUBICLE_GROUPS_SUCCESS:
            let mapIdToName = {};
            action.payload.results.forEach(cubicleGroup => {
                mapIdToName[cubicleGroup.id] = cubicleGroup.name;
            });

            return {
                ...state,
                cubicleGroupsList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
                mapIdToName,
            };
        default:
            return state;
    }
}

const initialAdminCubicleGroupsState = {
    adminCubicleGroupsList: [],
    next: null,
    previous: null,
    count: 0
};

export const AdminCubicleGroupsReducer = (state = initialAdminCubicleGroupsState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_CUBICLE_GROUPS_SUCCESS:
            return {
                ...state,
                adminCubicleGroupsList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            };
        default:
            return state;
    }
}
