import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_LOGOFF_TIME,
    DELETE_LOGOFF_TIME,
    UPDATE_LOGOFF_TIME,
    GET_ADMIN_LIST_LOGOFF_TIMES,
    getAdminListLogoffTimesSuccess,
} from '../actions/logoff_times.actions';
import { createLogoffTime, getLogoffTimes, removeLogoffTime, updateLogoffTime } from '../../services/logoffTimesService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from '../actions/ui.actions';
import { CLIENT_ADMIN_LOGOFF_TIMES } from '../../navigation/ROUTE_CONSTANTS';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getAdminListLogoffTimesEffect
 */
function* getAdminListLogoffTimesEffect() {
    yield put(enableOverlay('Loading logoff times ...'));
    try {
        const logoffTimesList = yield call(getLogoffTimes);
        yield put(getAdminListLogoffTimesSuccess(logoffTimesList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createLogoffTimeEffect
 */
function* createLogoffTimeEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating logoff time ...'));
        const logoffTime = yield call(createLogoffTime, payload);
        if (logoffTime) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The logoff time has been created!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_LOGOFF_TIMES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateLogoffTimeEffect
 */
function* updateLogoffTimeEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating logoff time ...'));
        const logoffTime = yield call(updateLogoffTime, payload);
        if (logoffTime) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The logoff time has been updated!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_LOGOFF_TIMES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteLogoffTimeEffect
 */
function* deleteLogoffTimeEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting logoff time ...'));
        const logoffTime = yield call(removeLogoffTime, payload);
        if (logoffTime) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The logoff time has been deleted!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_LOGOFF_TIMES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchGetAdminListLogoffTimes() {
    yield takeEvery(GET_ADMIN_LIST_LOGOFF_TIMES, getAdminListLogoffTimesEffect);
}

export function* watchCreateLogoffTime() {
    yield takeEvery(CREATE_LOGOFF_TIME, createLogoffTimeEffect);
}

export function* watchUpdateLogoffTime() {
    yield takeEvery(UPDATE_LOGOFF_TIME, updateLogoffTimeEffect);
}

export function* watchDeleteLogoffTime() {
    yield takeEvery(DELETE_LOGOFF_TIME, deleteLogoffTimeEffect);
}

function* logoffTimesSaga() {
    yield all([
        fork(watchGetAdminListLogoffTimes),
        fork(watchCreateLogoffTime),
        fork(watchUpdateLogoffTime),
        fork(watchDeleteLogoffTime),
    ]);
}

export default logoffTimesSaga;
