import axios from "axios";
import { INVOICES } from "./API_CONSTANTS"

export const postInvoice = (invoice) => {
    return new Promise((resolve, reject) => {
        try {
            axios.post(INVOICES, invoice)
                .then((res) => {
                    console.log("Invoice added: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Post invoice error: ", err);
                    reject("Post invoice error!");
                });
        } catch (error) {
            console.error("Post invoice error!", error);
            reject("Post invoice error!");
        }
    });
};

export const deleteInvoice = (invoiceId) => {
    return new Promise((resolve, reject) => {
        try {
            axios.delete(INVOICES + '/' + invoiceId)
                .then((res) => {
                    console.log("Invoice deleted: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Delete invoice error: ", err);
                    reject("Delete invoice error");
                });
        } catch (error) {
            console.error("Delete invoice error!", error);
            reject("Delete invoice error!");
        }
    });
};


export const updateInvoice = ({invoiceId, invoiceData}) => {
    return new Promise((resolve, reject) => {
        try {
            axios.put(INVOICES + '/' + invoiceId, invoiceData)
                .then((res) => {
                    console.log("Invoice updated: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Update invoice error: ", err);
                    reject("Update invoice error");
                });
        } catch (error) {
            console.error("Update invoice error!", error);
            reject("Update invoice error!");
        }
    });
};
