import {createAction} from "@reduxjs/toolkit";

// Client type
export const CREATE_CLIENT = '[CLIENTS] Client created';
export const UPDATE_CLIENT = '[CLIENTS] Client updated';
export const DELETE_CLIENT = '[CLIENTS] Client deleted';
export const GET_LIST_CLIENTS = '[CLIENTS] Getting list clients';
export const GET_LIST_CLIENTS_SUCCESS = '[CLIENTS] Getting clients success!';
export const GET_ADMIN_LIST_CLIENTS = '[CLIENTS] Getting admin list procedures';
export const GET_ADMIN_LIST_CLIENTS_SUCCESS = '[CLIENTS] Getting admin procedures success!';

// Client actions
export const createClient = createAction(CREATE_CLIENT);
export const updateClient = createAction(UPDATE_CLIENT);
export const deleteClient = createAction(DELETE_CLIENT);
export const getClients = createAction(GET_LIST_CLIENTS);
export const getClientsSuccess = createAction(GET_LIST_CLIENTS_SUCCESS);
export const getAdminListClients = createAction(GET_ADMIN_LIST_CLIENTS);
export const getAdminListClientsSuccess = createAction(GET_ADMIN_LIST_CLIENTS_SUCCESS);
