import axios from 'axios';
import { API_CONFIGURATIONS } from './API_CONSTANTS';

export const getConfigurations = (endpoint) => {
    const url = `${API_CONFIGURATIONS}/${endpoint}`;

    return new Promise((resolve, reject) => {
        try {
            axios
                .get(url)
                .then((res) => {
                    console.log('Configuration loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get configurations times error: ', err);
                    reject('Get configurations times error');
                });
        } catch (error) {
            console.error('Get configurations times error: ', error);
            reject('Get configurations times error');
        }
    });
};

export const updateConfiguration = (config) => {
    const url = `${API_CONFIGURATIONS}/${config.configKey}`;

    return new Promise((resolve, reject) => {
        try {
            axios
                .put(url, { value: config.value })
                .then((res) => {
                    console.log('configuration data updated: ', res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('configuration data error: ', err);
                    reject('configuration data error!');
                });
        } catch (error) {
            reject('configuration data error!');
        }
    });
};
