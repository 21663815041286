import { Col, Container, Row } from "reactstrap";

export const NotFound = () => {
    return (
        <Container>
            <Row>
                <Col>&nbsp;</Col>
            </Row>
            <Row>
                <Col>
                    <h3 className='mb-4 mt-2'>Not Found</h3>
                </Col>
            </Row>
        </Container>
    )
}

export default NotFound;
