import { GET_USERS_LIST_BYADMIN_SUCCESS } from "../actions/admin.users.actions";


const initialUsersByAdminState = {
    usersList: [],
};


export const UsersByAdminReducer = (state = initialUsersByAdminState, action) => {
    switch (action.type) {
        case GET_USERS_LIST_BYADMIN_SUCCESS:
            return {
                ...state,
                usersList: action.payload.results,
            };
        default:
            return state;
    }
}
