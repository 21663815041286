import { Col, Container, Row } from "reactstrap";

export const NotAuthorized = () => {
    return (
        <Container>
            <Row>
                <Col>&nbsp;</Col>
            </Row>
            <Row>
                <Col>
                    <h3 className='mb-4 mt-2'>Not Authorized to access requested page.</h3>
                </Col>
            </Row>
        </Container>
    )
}

export default NotAuthorized;
