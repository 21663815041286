import {createAction} from "@reduxjs/toolkit";

// Logoff time type
export const CREATE_LOGOFF_TIME = '[LOGOFF_TIMES] Logoff time created';
export const UPDATE_LOGOFF_TIME = '[LOGOFF_TIMES] Logoff time updated';
export const DELETE_LOGOFF_TIME = '[LOGOFF_TIMES] Logoff time deleted';
export const GET_ADMIN_LIST_LOGOFF_TIMES = '[LOGOFF_TIMES] Getting admin list procedures';
export const GET_ADMIN_LIST_LOGOFF_TIMES_SUCCESS = '[LOGOFF_TIMES] Getting admin procedures success!';

// Logoff time actions
export const createLogoffTime = createAction(CREATE_LOGOFF_TIME);
export const updateLogoffTime = createAction(UPDATE_LOGOFF_TIME);
export const deleteLogoffTime = createAction(DELETE_LOGOFF_TIME);
export const getAdminListLogoffTimes = createAction(GET_ADMIN_LIST_LOGOFF_TIMES);
export const getAdminListLogoffTimesSuccess = createAction(GET_ADMIN_LIST_LOGOFF_TIMES_SUCCESS);
