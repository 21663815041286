import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
import appSaga from './saga';
import { AdminClientsReducer } from './reducers/clients.reducers';
import {
    AdminCubicleGroupsReducer,
    CubicleGroupsReducer,
} from './reducers/cubicle_groups.reducers';
import {
    AdminInvoicesReducer,
    AdminInvoiceReducer,
} from './reducers/admin.invoices.reducers';
import {
    AdminProceduresReducer,
    ProceduresReducer,
} from './reducers/procedures.reducers';
import { AdminSettingsReducer } from './reducers/admin.settings.reducers';
import { AuthReducer } from './reducers/auth.reducer';
import { CubicleLogsReducer } from './reducers/cubicle_logs.reducers';
import {
    CubicleQueueReducer,
    CubiclesReducer,
    CubiclesAdminReducer,
} from './reducers/cubicles.reducers';
import { ErrorReducer } from './reducers/error.reducer';
import { InvoicesReducer } from './reducers/invoices.reducer';
import { LayoutReducer } from './reducers/layout.reducer';
import { AdminLogoffTimesReducer } from './reducers/logoff_times.reducers';
import { MyCubicleReducer } from './reducers/my_cubicle.reducer';
import { UIReducer } from './reducers/ui.reducer';
import { UsersByAdminReducer } from './reducers/admin.users.reducers';
import {
    UsersReducer,
    FacultiesLoggedInReducer,
} from './reducers/users.reducer';
import { AdminConfigurationsReducer } from './reducers/admin.configurations.reducers';

const appReducer = {
    // list of reducers
    adminClients: AdminClientsReducer,
    adminCubicleGroups: AdminCubicleGroupsReducer,
    adminInvoice: AdminInvoiceReducer,
    adminInvoices: AdminInvoicesReducer,
    adminLogoffTimes: AdminLogoffTimesReducer,
    adminProcedures: AdminProceduresReducer,
    adminSettings: AdminSettingsReducer,
    adminConfigurations: AdminConfigurationsReducer,
    auth: AuthReducer,
    cubicleGroups: CubicleGroupsReducer,
    cubicleLogs: CubicleLogsReducer,
    cubicleQueue: CubicleQueueReducer,
    cubicles: CubiclesReducer,
    cubiclesAdmin: CubiclesAdminReducer,
    errors: ErrorReducer,
    facultiesLoggedIn: FacultiesLoggedInReducer,
    invoices: InvoicesReducer,
    layout: LayoutReducer,
    myCubicle: MyCubicleReducer,
    procedures: ProceduresReducer,
    ui: UIReducer,
    users: UsersReducer,
    usersByAdmin: UsersByAdminReducer,
};

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const devMode = process.env.NODE_ENV === 'development';

export const store = configureStore({
    reducer: appReducer,
    devTools: devMode,
    middleware: (getDefaultMiddleware) => {
        let middleware = getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware);
        if (devMode) {
            middleware.concat(logger);
        }
        return middleware;
    },
});

sagaMiddleware.run(appSaga);
