export const customStyles = {
    rows: {
        style: {
            minHeight: '3rem',
        },
    },
    headCells: {
        style: {
            fontSize: '1rem',
            backgroundColor: '#f8f9fa',
            border: '1px solid rgb(0 0 0 / 12%)',
        },
    },
    cells: {
        style: {
            fontSize: '1rem',
            '&:not(:last-of-type)': {
                borderRight: '1px solid rgb(0 0 0 / 12%)',
            },
        },
    },
    pagination: {
        style: {
            fontSize: '1rem',
        },
    },
};