import { GET_ADMIN_LIST_CLIENTS_SUCCESS } from "../actions/clients.actions";

const initialAdminClientsState = {
    adminClientsList: [],
    next: null,
    previous: null,
    count: 0
};

export const AdminClientsReducer = (state = initialAdminClientsState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_CLIENTS_SUCCESS:
            state.clientsList = action.payload.results;
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;

            return state;
        default:
            return state;
    }
}
