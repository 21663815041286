import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { logout } from "../../redux/actions/auth.actions";
import { setUnoccupiedCubicleLog } from "../../redux/actions/cubicle_logs.actions";
import { USER_ROLE_STUDENT } from "../../redux/reducers/auth.reducer";

const Logout = () => {
    const dispatch = useDispatch();
    const userLogged = useSelector((state) => state.users.userLogged);

    useEffect(() => {
        if (userLogged?.role === USER_ROLE_STUDENT) {
            dispatch(setUnoccupiedCubicleLog());
        } else {
            dispatch(logout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container className='min-vh-72'>
            <span>&nbsp;</span>
        </Container>
    );
}

export default Logout;
