import axios from 'axios';
import { buildPaginationUrlParams } from '../utils/index';
import { API_PROCEDURES } from './API_CONSTANTS';

export const getProcedures = (paginationParams = {}) => {
    paginationParams = {
        ...paginationParams,
        page: 1,
        limit: 99,
    };

    const url = API_PROCEDURES + buildPaginationUrlParams(paginationParams);

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Procedures loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get procedures error: ', err);
                    reject('Get procedures error');
                });
        } catch (error) {
            console.error('Get procedures error: ', error);
            reject('Get procedures error');
        }
    });
};

export const getOneProcedure = (procedureId) => {
    const url = API_PROCEDURES + '/' + procedureId;

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log('Procedure loaded: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Get one procedure error: ', err);
                    reject('Get one procedure error');
                });
        } catch (error) {
            console.error('Get one procedure error: ', error);
            reject('Get one procedure error');
        }
    });
}

export const createProcedure = (procedure) => {
    const url = API_PROCEDURES;

    return new Promise((resolve, reject) => {
        try {
            axios.post(url, procedure)
                .then((res) => {
                    console.log('Procedure created: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Create procedure error: ', err);
                    reject('Create procedure error');
                });
        } catch (error) {
            console.error('Create procedure error: ', error);
            reject('Create procedure error');
        }
    });
}

export const updateProcedure = (procedure) => {
    const url = API_PROCEDURES + '/' + procedure.id;

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, procedure)
                .then((res) => {
                    console.log('Procedure updated: ', res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log('Update procedure error: ', err);
                    reject('Update procedure error');
                });
        } catch (error) {
            console.error('Update procedure error: ', error);
            reject('Update procedure error');
        }
    });
}

export const removeProcedure = (procedureId) => {
    const url = API_PROCEDURES + '/' + procedureId;

    return new Promise((resolve, reject) => {
        try {
            axios.delete(url)
                .then((res) => {
                    console.log('Procedure removed: ', res);
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Remove procedure error: ', err);
                    reject('Remove procedure error');
                });
        } catch (error) {
            console.error('Remove procedure error: ', error);
            reject('Remove procedure error');
        }
    });
}
