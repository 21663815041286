import { DateTime, Duration, Settings } from "luxon";

// Set of formats wrapped from luxon.  https://moment.github.io/luxon/#/formatting?id=presets
export const FORMAT_DATE_SHORT = DateTime.DATE_SHORT;
export const FORMAT_DATE_MED = DateTime.DATE_MED;
export const FORMAT_DATE_MED_WITH_WEEKDAY = DateTime.DATE_MED_WITH_WEEKDAY;
export const FORMAT_TIME_24_SIMPLE = DateTime.TIME_24_SIMPLE;
export const FORMAT_DATETIME_SHORT = DateTime.DATETIME_SHORT;

export const FORMAT_DURATION_SHORT = 'h:mm:ss';

/**
 *
 * @param {string} timeZone - The time zone to set as default
 */
export const setDefaultTimeZone = (timeZone) => {
    Settings.defaultZone = timeZone;
}

/**
 * Convert a date string from API to a luxon.DateTime object
 * @param {string} isoString - The date time string to convert to a luxon.DateTime object
 * @returns {luxon.DateTime} - The luxon.DateTime object
 */
export const parseToDateTime = (isoString) => {
    return DateTime.fromISO(isoString);
}

/**
 *
 * @param {DateTime} dateTime - The date time to convert to a string for sending to API
 * @returns {string} - The ISO string of the date time
 */
export const dateTimeToAPI = (dateTime) => {
    return dateTime.toUTC().toISO();
}

/**
 *
 * @param {string} isoString - The date time string to convert to a string for display
 * @param {string} formatType - The format type to use for display
 * @returns {string}
 */
export const isoStringToFormated = (isoString, formatType = FORMAT_DATE_SHORT) => {
    return parseToDateTime(isoString).toLocaleString(formatType);
}

/**
 *
 * @param {DateTime} dateTime - The luxon.DateTime to convert to a string for display
 * @param {string} formatType - The format type to use for display
 * @returns {string}
 */
export const dateTimeToFormated = (dateTime, formatType = FORMAT_DATE_SHORT) => {
    return dateTime.toLocaleString(formatType);
}

/**
 *
 * @param {object} params - See interface DateObjectUnits
 * @returns {DateTime}
 */
export const createFromParams = (params) => {
    return DateTime.fromObject(params);
}

/**
 *
 * @param {DateTime} dateTime - The luxon.DateTime object to convert to a JS Date object
 * @returns {Date} - JS Date
 */
export const convertToJSDate = (dateTime) => {
    return dateTime.toJSDate();
}

/**
 *
 * @param {Date} jsDate - The JS Date object to convert to a luxon.DateTime
 * @returns {DateTime}
 */
export const convertToDateTime = (jsDate) => {
    return DateTime.fromJSDate(jsDate);
}

/**
 *
 * @param {integer} minutes - Minutes to convert to luxon.Duration
 * @param {string} formatType - The format type to use for display
 * @returns {string}
 */
export const minutesToDurationFormated = (minutes, formatType = FORMAT_DURATION_SHORT) => {
    return Duration.fromObject({ minutes }).toFormat(formatType);
}

/**
 *
 * @param {integer} seconds - Seconds to convert to luxon.Duration
 * @param {string} formatType - The format type to use for display
 * @returns {string}
 */
 export const secondsToDurationFormated = (seconds, formatType = FORMAT_DURATION_SHORT) => {
    return Duration.fromObject({ seconds }).toFormat(formatType);
}