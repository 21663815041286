import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    GET_USERS_LIST_BYADMIN,
    getUsersListByAdminSuccess,
    CREATE_USER_BYADMIN,
    createUserByAdminSuccess,
    UPDATE_USER_BYADMIN,
    removeUserByAdminSuccess,
    REMOVE_USER_BYADMIN,
    getUsersListByAdmin,
    SET_SINGLE_LOGIN_BUTTON,
    CLEAR_SINGLE_LOGIN_BUTTON
} from "../actions/admin.users.actions";
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from "../actions/ui.actions";
import { genericError } from "../actions/error.actions";
import {
    getUsersByAdmin,
    postUserByAdmin,
    updateUserByAdmin,
    removeUserByAdmin,
    setSingleLoginButton,
    clearSingleLoginButton
} from "../../services/userAdminService";
import { USER_HOME } from "../../navigation/ROUTE_CONSTANTS";
import { TOAST_SEVERITY_SUCCESS } from "../reducers/ui.reducer";

/**
 * getUsersByAdminEffect
 * @param {*} param0
 */
function* getUsersByAdminEffect() {
    yield put(enableOverlay('Loading users ...'));
    try {
        const usersList = yield call(getUsersByAdmin);
        yield put(getUsersListByAdminSuccess(usersList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createUserByAdminEffect
 * @param {*} param0
 */
function* createUserByAdminEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating new account by admin...'));
        const createdUser = yield call(postUserByAdmin, payload.user);
        if (createdUser) {
            yield put(createUserByAdminSuccess(createdUser));
            yield put(getUsersListByAdmin());
            yield put(disableOverlay());
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The user has been created!',
            }));
            yield put(setNavigateTo(USER_HOME));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * updateUserByAdminEffect
 * @param {*} param0
 */
function* updateUserByAdminEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating user by admin...'));
        const updateUser = yield call(updateUserByAdmin, payload.user);
        if (updateUser) {
            yield put(createUserByAdminSuccess(updateUser));
            yield put(disableOverlay());
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The user has been updated!',
            }));
            yield put(setNavigateTo(USER_HOME));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

/**
 * removeUserByAdminEffect
 */
function* removeUserByAdminEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting user by admin ...'));
        const user = yield call(removeUserByAdmin, payload.user);
        if (user) {
            yield put(removeUserByAdminSuccess(true));
            yield put(disableOverlay());
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The user has been deleted!',
            }));
            yield put(setNavigateTo(USER_HOME));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
        yield put(disableOverlay());
    }
}

function* setSingleLoginButtonEffect({ payload }) {
    yield put(enableOverlay('Enabling single login ...'));
    try {
        const singleLoginButtonData = yield call(setSingleLoginButton, payload);
        if (singleLoginButtonData) {
            localStorage.setItem('ca-sbl', JSON.stringify(singleLoginButtonData));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

function* clearSingleLoginButtonEffect({ payload }) {
    yield put(enableOverlay('Disabling single login ...'));
    try {
        yield call(clearSingleLoginButton, payload);
        localStorage.removeItem('ca-sbl');
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * Watchers
 */
export function* watchGetUsersByAdmin() {
    yield takeEvery(GET_USERS_LIST_BYADMIN, getUsersByAdminEffect);
}

export function* watchCreateUserByAdmin() {
    yield takeEvery(CREATE_USER_BYADMIN, createUserByAdminEffect);
}

export function* watchUpdateUserByAdmin() {
    yield takeEvery(UPDATE_USER_BYADMIN, updateUserByAdminEffect);
}

export function* watchRemoveUserByAdmin() {
    yield takeEvery(REMOVE_USER_BYADMIN, removeUserByAdminEffect);
}

export function* watchSetSingleLoginButton() {
    yield takeEvery(SET_SINGLE_LOGIN_BUTTON, setSingleLoginButtonEffect);
}

export function* watchClearSingleLoginButton() {
    yield takeEvery(CLEAR_SINGLE_LOGIN_BUTTON, clearSingleLoginButtonEffect);
}

function* usersByAdminSaga() {
    yield all([
        fork(watchGetUsersByAdmin),
        fork(watchCreateUserByAdmin),
        fork(watchUpdateUserByAdmin),
        fork(watchRemoveUserByAdmin),
        fork(watchSetSingleLoginButton),
        fork(watchClearSingleLoginButton),
    ]);
}

export default usersByAdminSaga;
