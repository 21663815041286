import {createAction} from "@reduxjs/toolkit";

// My cubicle types
export const GET_MY_CUBICLE = '[MY_CUBICLE] Getting my cubicle';
export const GET_MY_CUBICLE_SUCCESS = '[MY_CUBICLE] Getting my cubicle success!';
export const SET_MY_CUBICLE_DATA = '[MY_CUBICLE] Setting my cubicle data';
export const RESET_MY_CUBICLE = '[MY_CUBICLE] Reseting my cubicle';
export const SHOULD_REFRESH_MY_CUBICLE = '[MY_CUBICLE] Should refresh my cubicle?';

// My cubicle actions
export const getMyCubicle = createAction(GET_MY_CUBICLE);
export const getMyCubicleSuccess = createAction(GET_MY_CUBICLE_SUCCESS);
export const setMyCubicleData = createAction(SET_MY_CUBICLE_DATA);
export const resetMyCubicle = createAction(RESET_MY_CUBICLE);
export const shouldRefreshMyCubicle = createAction(SHOULD_REFRESH_MY_CUBICLE);
