import { Badge } from 'reactstrap';

const CubicleBadge = ({ cubicleColor, cubicleName }) => {

    return (
        <Badge color={cubicleColor} className={['transparent'].includes(cubicleColor) ? ' text-dark' : ''} >
            <span className="h5">{cubicleName}</span>
        </Badge>
    )
};

export default CubicleBadge;
