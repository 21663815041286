import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { shouldRefreshMyCubicle } from '../redux/actions/my_cubicle.actions';
import { retrieveFacultiesLoggedInSuccess } from '../redux/actions/users.actions';
import { MB_RESOURCE_AUTOMATED_LOGOUT, MB_RESOURCE_CUBICLES_QUEUE, MB_RESOURCE_FACULTIES_LOGGED_IN } from "../services/API_CONSTANTS";
import { getCubicleQueueSuccess } from "../redux/actions/cubicles.actions";
import { automatedLogout } from '../redux/actions/auth.actions';

const AppWebsocket = () => {
    const dispatch = useDispatch();
    const websocketUrl = useSelector(state => state.users.websocketUrl);

    const { lastJsonMessage } = useWebSocket(websocketUrl, {
        retryOnError: true,
        shouldReconnect: (closeEvent) => true,
        reconnectAttempts: 180,
        reconnectInterval: 10000, // Try reconnection by 30 minutes
    });

    useEffect(() => {
        if (lastJsonMessage !== null) {
            // Update state according to the message received
            for (let resource in lastJsonMessage) {
                switch (resource) {
                    case MB_RESOURCE_CUBICLES_QUEUE:
                        dispatch(getCubicleQueueSuccess(lastJsonMessage[resource]));
                        dispatch(shouldRefreshMyCubicle(lastJsonMessage[resource]));
                        break;
                    case MB_RESOURCE_FACULTIES_LOGGED_IN:
                        dispatch(retrieveFacultiesLoggedInSuccess(lastJsonMessage[resource]));
                        break;
                    case MB_RESOURCE_AUTOMATED_LOGOUT:
                        dispatch(automatedLogout(lastJsonMessage[resource]));
                        break;
                    default:
                        console.log(`Unhandled resource: ${resource}`);
                }
            }
        }
    }, [lastJsonMessage, dispatch]);

    return null;
};

export default AppWebsocket;
