export const USER_HOME = '/';
export const LOGIN_FORM = '/login';
export const RESET_PASSWORD = '/reset-password';
export const CREATE_ACCOUNT = '/create-account';
export const USER_PROFILE = '/profile';
export const DASHBOARD = '/dashboard';
export const LOGOUT = '/logout';

// admin routes
export const ADMIN_INVOICES = '/admin/invoices';

// Student
export const STUDENT = '/student';
export const STUDENT_HOME = STUDENT + '/home';
export const STUDENT_MY_ACCOUNT = STUDENT + '/my-account';
export const STUDENT_REQUEST_SUBMITTED = STUDENT + '/request-submitted';
export const STUDENT_CHECKOUT = STUDENT + '/checkout';
export const STUDENT_REQUEST_HELP = STUDENT + '/request-help';

// Faculty
export const FACULTY = '/faculty';
export const FACULTY_HOME = FACULTY + '/home';
export const FACULTY_MY_ACCOUNT = FACULTY + '/my-account';

// Client Admin
export const CLIENT_ADMIN = '/university-admin';
export const CLIENT_ADMIN_USERS = CLIENT_ADMIN + '/users';
export const CLIENT_ADMIN_CREATE_USER = CLIENT_ADMIN_USERS + '/create';
export const CLIENT_ADMIN_EDIT_USER = CLIENT_ADMIN_USERS + '/edit/:userId';
export const CLIENT_ADMIN_DELETE_USER = CLIENT_ADMIN_USERS + '/delete/:userId';

export const CLIENT_ADMIN_CUBICLES = CLIENT_ADMIN + '/cubicles';
export const CLIENT_ADMIN_CREATE_CUBICLE = CLIENT_ADMIN_CUBICLES + '/create';
export const CLIENT_ADMIN_EDIT_CUBICLE = CLIENT_ADMIN_CUBICLES + '/edit/:cubicleId';
export const CLIENT_ADMIN_DELETE_CUBICLE = CLIENT_ADMIN_CUBICLES + '/delete/:cubicleId';

export const CLIENT_ADMIN_LOGOFF_TIMES = CLIENT_ADMIN + '/loggoff-times';
export const CLIENT_ADMIN_CREATE_LOGOFF_TIME = CLIENT_ADMIN_LOGOFF_TIMES + '/create';
export const CLIENT_ADMIN_EDIT_LOGOFF_TIME = CLIENT_ADMIN_LOGOFF_TIMES + '/edit/:logoffTimeId';
export const CLIENT_ADMIN_DELETE_LOGOFF_TIME = CLIENT_ADMIN_LOGOFF_TIMES + '/delete/:logoffTimeId';

export const CLIENT_ADMIN_PROCEDURES = CLIENT_ADMIN + '/procedures';
export const CLIENT_ADMIN_CREATE_PROCEDURE = CLIENT_ADMIN_PROCEDURES + '/create';
export const CLIENT_ADMIN_EDIT_PROCEDURE = CLIENT_ADMIN_PROCEDURES + '/edit/:procedureId';
export const CLIENT_ADMIN_DELETE_PROCEDURE = CLIENT_ADMIN_PROCEDURES + '/delete/:procedureId';

export const CLIENT_ADMIN_CUBICLE_GROUPS = CLIENT_ADMIN + '/cubicle-groups';
export const CLIENT_ADMIN_CREATE_CUBICLE_GROUP = CLIENT_ADMIN_CUBICLE_GROUPS + '/create';
export const CLIENT_ADMIN_EDIT_CUBICLE_GROUP = CLIENT_ADMIN_CUBICLE_GROUPS + '/edit/:cubicleGroupId';
export const CLIENT_ADMIN_DELETE_CUBICLE_GROUP = CLIENT_ADMIN_CUBICLE_GROUPS + '/delete/:cubicleGroupId';

export const CLIENT_ADMIN_FACULTY_REPORT = FACULTY + '/faculty-report';
export const CLIENT_ADMIN_STUDENT_REPORT = FACULTY + '/student-report';

// General
export const CUBICLES_MAP = '/cubicles-map';

// Pages
export const PAGES = '/pages';
export const PAGES_ABOUT = PAGES + '/about';
export const PAGES_PRIVACY = PAGES + '/privacy';
export const PAGES_TERMS = PAGES + '/terms';
export const PAGES_HELP = PAGES + '/help';
export const PAGES_CONTACT = PAGES + '/contact';

export const PAGES_NOT_AUTHORIZED = PAGES + '/not-authorized';