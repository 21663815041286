import { useSelector } from 'react-redux';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { getBorderClassNames } from '../../utils/Navbar/getClasses';

const ItemTypeLink = ({ linkProps, index }) => {
    const indexDropdownMobile = useSelector((state) => state.ui.indexDropdownMobile);
    const showDropdownMobile = useSelector((state) => state.ui.showDropdownMobile);

    const classNames = getBorderClassNames(
        linkProps.isActive,
        index,
        showDropdownMobile,
        indexDropdownMobile
    );

    return (
        <NavItem className={classNames}>
            <NavLink
                tag={RouterNavLink}
                to={linkProps.href ? linkProps.href : ''}
                activeClassName="dummy-active"
                className={
                    'mx-1 px-md-2 py-2 my-0 ' +
                    (linkProps.className ? linkProps.className : '')
                }
                {...(linkProps.onclick && {
                    onClick: (e) => {
                        e.preventDefault();
                        linkProps.onclick();
                    },
                })}
            >
                <span className="d-inline-block py-1 py-md-0">
                    {linkProps.text}
                </span>
            </NavLink>
        </NavItem>
    );
};

export default ItemTypeLink;
