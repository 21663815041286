import axios from "axios";
import {
    API_URL_ADMIN_SETTINGS_AUTOMATIC_PROCESSING,
    API_URL_ADMIN_SETTINGS_FEE_LABEL,
    API_URL_ADMIN_SETTINGS_FEE_PERCENTAGE,
    API_URL_ADMIN_SETTINGS_PROCESSING_DAY,
    INVOICES,
} from "./API_CONSTANTS"

const mapSettingKeyEndpoint = {
    invoicesProcessingDay: API_URL_ADMIN_SETTINGS_PROCESSING_DAY,
    invoicesMarginFeePercentage: API_URL_ADMIN_SETTINGS_FEE_PERCENTAGE,
    invoicesMarginFeeLabel: API_URL_ADMIN_SETTINGS_FEE_LABEL,
    invoicesAutomaticGeneration: API_URL_ADMIN_SETTINGS_AUTOMATIC_PROCESSING,
};

export const getAdminSettingsValue = (setting) => {
    const settingKey = setting.key;
    const url = INVOICES + mapSettingKeyEndpoint[settingKey];

    return new Promise((resolve, reject) => {
        try {
            axios.get(url)
                .then((res) => {
                    console.log("Admin settings value loaded: ", res);
                    let response = {
                        key: settingKey,
                        value: res.data.value,
                    }
                    resolve(response);
                })
                .catch((err) => {
                    console.log("Get admin settings value error: ", err);
                    reject("Get admin settings value error");
                });
        } catch (error) {
            console.error("Get admin settings value error: ", error);
            reject("Get admin settings value error");
        }
    });
};

export const updateAdminSettingsValue = (setting) => {
    const settingKey = setting.key;
    const url = INVOICES + mapSettingKeyEndpoint[settingKey];
    const body = {value: setting.value};

    return new Promise((resolve, reject) => {
        try {
            axios.put(url, body)
                .then((res) => {
                    console.log("Admin settings value updated: ", res);
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("Update admin settings value error: ", err);
                    reject("Update admin settings value error!");
                });
        } catch (error) {
            console.error("Update admin settings value error!", error);
            reject("Update admin settings value error!");
        }
    });
};
