import { Container, Row, Col } from 'reactstrap';

const Contact = () => (
    <Container className='mb-5 min-vh-60'>
        <Row>
            <Col md={12}>
                <h3 className='mt-5 mb-4 pt-2 pb-2'>Contact</h3>
            </Col>
            <Col md={10}>
                <p className='mb-4'>
                    Email <a href="emailto:admin@wkgsolutions">admin@wkgsolutions</a> with any questions or concerns.
                </p>

            </Col>
        </Row>
    </Container>
);

export default Contact;