import { Container, Row, Col } from 'reactstrap';

const Privacy = () => (
    <Container className='mb-5 px-4 px-md-auto min-vh-60'>
        <Row>
            <Col md={12}>
                <h3 className='mt-5 mb-4 pt-2 pb-2'>Privacy</h3>
            </Col>
            <Col md={10}>
                <p className='mb-4'>
                    UMCA are committed to ensuring the privacy and accuracy of your confidential information. We do not actively share personal information gathered from our web servers.
                </p>
                <p className='mb-4'>
                    Our web servers generate temporary logs that contain the following information:
                    <ul>
                        <li>Internet address of computer being used</li>
                        <li>Web pages requested</li>
                        <li>Referring web page</li>
                        <li>Browser used</li>
                        <li>Date and Time</li>
                        <li>Unique person identifier</li>
                    </ul>
                </p>
                <p className='mb-4'>
                    The data is used in aggregate by system administrators and web content coordinators to tune the website for its efficiency and is not ordinarily associated with specific individuals. Summary reports produced from the logs help web publishers determine what web browsers and pages are most popular.
                </p>

            </Col>
        </Row>
    </Container>
);

export default Privacy;