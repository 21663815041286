import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_CUBICLE_GROUP,
    DELETE_CUBICLE_GROUP,
    UPDATE_CUBICLE_GROUP,
    GET_ADMIN_LIST_CUBICLE_GROUPS,
    GET_LIST_CUBICLE_GROUPS,
    getAdminListCubicleGroupsSuccess,
    getCubicleGroupsSuccess,
} from '../actions/cubicle_groups.actions';
import { createCubicleGroup, getCubicleGroups, removeCubicleGroup, updateCubicleGroup } from '../../services/cubicleGroupsService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from '../actions/ui.actions';
import { CLIENT_ADMIN_CUBICLE_GROUPS } from '../../navigation/ROUTE_CONSTANTS';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getListCubicleGroupsEffect
 */
function* getListCubicleGroupsEffect() {
    try {
        const cubicleGroupsList = yield call(getCubicleGroups);
        yield put(getCubicleGroupsSuccess(cubicleGroupsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getAdminListCubicleGroupsEffect
 */
function* getAdminListCubicleGroupsEffect() {
    yield put(enableOverlay('Loading cubicle groups ...'));
    try {
        const cubicleGroupsList = yield call(getCubicleGroups);
        yield put(getAdminListCubicleGroupsSuccess(cubicleGroupsList));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createCubicleGroupEffect
 */
function* createCubicleGroupEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating cubicle group ...'));
        const cubicleGroup = yield call(createCubicleGroup, payload);
        if (cubicleGroup) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The cubicle group has been created!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_CUBICLE_GROUPS));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateCubicleGroupEffect
 */
function* updateCubicleGroupEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating cubicle group ...'));
        const cubicleGroup = yield call(updateCubicleGroup, payload);
        if (cubicleGroup) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The cubicle group has been updated!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_CUBICLE_GROUPS));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteCubicleGroupEffect
 */
function* deleteCubicleGroupEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting cubicle group ...'));
        const cubicleGroup = yield call(removeCubicleGroup, payload);
        if (cubicleGroup) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The cubicle group has been deleted!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_CUBICLE_GROUPS));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

export function* watchGetListCubicleGroups() {
    yield takeEvery(GET_LIST_CUBICLE_GROUPS, getListCubicleGroupsEffect);
}

export function* watchGetAdminListCubicleGroups() {
    yield takeEvery(GET_ADMIN_LIST_CUBICLE_GROUPS, getAdminListCubicleGroupsEffect);
}

export function* watchCreateCubicleGroup() {
    yield takeEvery(CREATE_CUBICLE_GROUP, createCubicleGroupEffect);
}

export function* watchUpdateCubicleGroup() {
    yield takeEvery(UPDATE_CUBICLE_GROUP, updateCubicleGroupEffect);
}

export function* watchDeleteCubicleGroup() {
    yield takeEvery(DELETE_CUBICLE_GROUP, deleteCubicleGroupEffect);
}

function* cubicleGroupsSaga() {
    yield all([
        fork(watchGetListCubicleGroups),
        fork(watchGetAdminListCubicleGroups),
        fork(watchCreateCubicleGroup),
        fork(watchUpdateCubicleGroup),
        fork(watchDeleteCubicleGroup),
    ]);
}

export default cubicleGroupsSaga;
