import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faTriangleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import { closeNotificationModal } from '../../redux/actions/ui.actions';
import { logout } from '../../redux/actions/auth.actions';
import { Fragment } from 'react';

export const ICON_ERROR = 'error';
export const ICON_SUCCESS = 'success';
export const ICON_INFO = 'info';

const NotificationModal = () => {
    const dispatch = useDispatch();
    const notificationModal = useSelector(state => state.ui.notificationModal);

    const handleClose = () => {
        dispatch(closeNotificationModal());
    };

    const handleOnAccept = () => {
        if (notificationModal.handleAccept === 'logout') {
            dispatch(logout());
            dispatch(closeNotificationModal());
        }
    };

    const iconDisplay = (iconType) => {
        let iconDisplay;
        switch (iconType) {
            case ICON_ERROR:
                iconDisplay = <FontAwesomeIcon icon={faTriangleExclamation} />
                break;
            case ICON_SUCCESS:
                iconDisplay = <FontAwesomeIcon icon={faCircleCheck} />
                break;
            case ICON_INFO:
            default:
                iconDisplay = <FontAwesomeIcon icon={faCircleExclamation} />
        }
        return iconDisplay;
    }

    return (
        <Modal isOpen={notificationModal.isOpen} className={notificationModal.centered ? 'modal-class' : ''} >
            <ModalHeader>
                {iconDisplay(notificationModal.iconType)} {notificationModal.title}
            </ModalHeader>
            <ModalBody>
                {notificationModal.message}
            </ModalBody>
            <ModalFooter>
                {notificationModal.buttonResponse ?
                    <Fragment>
                        <Button color="secondary" onClick={handleOnAccept} >
                            {notificationModal.buttonAcceptText}
                        </Button>
                        <Button color="success" onClick={(e) => handleClose(e)} >
                            Cancel
                        </Button>
                    </Fragment>
                    :
                    <Button color="primary" onClick={handleClose} >
                        Accept
                    </Button>
                }
            </ModalFooter>
        </Modal >
    );
}

export default NotificationModal;
