import Cubicle, { CUBICLE_VARIANT_STUDENT_QUEUE } from '../../theme/components/Cubicle';
import { Row, Col } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCubicleQueue } from '../../redux/actions/cubicles.actions';
import { numberToOrdinal } from '../../utils';
import { REQUEST_STATUS_UNDER_WAY } from '../../redux/reducers/my_cubicle.reducer';

const StudentCubicleQueue = () => {
    const dispatch = useDispatch();
    const myCubicleId = useSelector(state => state.myCubicle.id);
    const cubicleQueue = useSelector(state => state.cubicleQueue);
    const [cubiclePosition, setCubiclePosition] = useState(false);
    const [queueForFacultyVisit, setQueueForFacultyVisit] = useState([]);

    useEffect(() => {
        dispatch(getCubicleQueue());
    }, [dispatch]);

    useEffect(() => {
        let queueForFacultyVisit = [];
        if (cubicleQueue.length > 0) {
            queueForFacultyVisit = cubicleQueue.filter(cubicle => cubicle.request_status === REQUEST_STATUS_UNDER_WAY);
            // Find my cubicle position in the queue
            const myCubiclePosition = queueForFacultyVisit.findIndex(cubicle => cubicle.id === myCubicleId);
            if (myCubiclePosition !== -1) {
                setCubiclePosition(numberToOrdinal(myCubiclePosition + 1));
            } else {
                setCubiclePosition(false);
            }
        }
        setQueueForFacultyVisit(queueForFacultyVisit);
    }, [cubicleQueue, myCubicleId]);

    return (
        <Fragment>
            <Row className='mt-4'>
                <Col>
                    <div className='fs-3 fw-semibold mt-1'>Status</div>
                </Col>
            </Row>
            {queueForFacultyVisit.length > 0 ? (
                <Fragment>
                    <Row className='pb-1 mt-3 border-bottom'>
                        <Col>
                            <div className='fs-5 fw-semibold lh-base mt-1 mb-2'>
                                Queue for faculty visit
                                {cubiclePosition && `: you are ${cubiclePosition} in the queue`}
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3 mb-5'>
                        {queueForFacultyVisit.map((cubicle, index) => (
                            <Col md={1} key={index}>
                                <Cubicle
                                    cubicleData={cubicle}
                                    variant={CUBICLE_VARIANT_STUDENT_QUEUE}
                                />
                            </Col>
                        ))}
                    </Row>
                </Fragment>
            ) : (
                <Row className='pb-1 mb-5 mt-3'>
                    <Col>
                        <div className='fs-5 fw-semibold lh-base mt-1 mb-2'>
                            Your request has not been selected by the faculty yet.
                        </div>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default StudentCubicleQueue;
