export const cleanSession = () => {
    localStorage.removeItem('ca-access');
    localStorage.removeItem('ca-refresh');
    localStorage.removeItem('ca-user');
}

export const buildPaginationUrlParams = ({page, limit, filter, order, sortBy, status, client_id})  => {
    let url = new URLSearchParams();

    if (page) {
        url.append('page', page);
    }
    if (limit) {
        url.append('pageSize', limit);
    }
    if (filter) {
        url.append('search', filter);
    }
    if (order && sortBy && order === 'desc') {
        sortBy = '-' + sortBy;
    }
    if (sortBy) {
        url.append('ordering', sortBy);
    }
    if (status) {
        url.append('status', status);
    }
    if (client_id) {
        url.append('client_id', client_id);
    }

    let queryString = url.toString();
    if (queryString) {
        queryString = '?' + queryString;
    }

    return queryString;
};

export const convertInvoiceRowToUI =  (result) => {
    return {
        // Renamed
        invoiceId: result.id,
        company: result.owner_title,
        customerName: result.owner_first_name + ' ' + result.owner_last_name,

        total: +result.total,
        paid: 0, // TODO
        balance: (result.total - 0), // TODO

        invoice_date: result.invoice_date,
        due_date: result.due_date,
        status: result.status,
        owner_account_id: result.owner_account_id,
    }
}

// Number to ordinal string formatter
export const currencyFormatter = (decimalPositions) => new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPositions,
    maximumFractionDigits: decimalPositions,
});
const englishOrdinalRules = new Intl.PluralRules('en', {type: 'ordinal'});
const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
};
export const numberToOrdinal = (number) => {
    const category = englishOrdinalRules.select(number);
    const suffix = suffixes[category];
    return (number + suffix);
}

export const cubiclesSortedByName = (a, b) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name < b.name) {
        return 1;
    }
    return 0;
}

export const setDateDayBeginning = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
}

export const setDateDayEnding = (date) => {
    date.setHours(23, 59, 59, 999);;
    return date;
}

export const userFullName = (user) => {
    return user.first_name + ' ' + user.last_name;
}

export const buildProcedureLabel = (procedure, otherProcedure = null) => {
    let procedureLabel;
    if (procedure.short_name) {
        procedureLabel = procedure.short_name + ' - ' + procedure.name;
    } else {
        procedureLabel = procedure.name;
    }

    if (procedure.need_details && otherProcedure) {
        procedureLabel += ': ' + otherProcedure;
    }

    return procedureLabel;
}