import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { STUDENT_HOME } from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CUBICLE_STATUS_CHECKOUT, CUBICLE_STATUS_OCCUPIED } from '../../redux/reducers/my_cubicle.reducer';
import CheckoutProceduresForm from '../../components/student/CheckoutProceduresForm';
import StudentCubicleQueue from '../../components/student/StudentCubicleQueue';

const Checkout = () => {
    const dispatch = useDispatch();
    const myCubicle = useSelector(state => state.myCubicle);
    const [lastCubicleStatus, setLastCubicleStatus] = useState(null);

    useEffect(() => {
        if (
            // No status available
            !myCubicle.cubicle_status ||
            // Page only available for occupied or checkout status
            ![CUBICLE_STATUS_OCCUPIED, CUBICLE_STATUS_CHECKOUT].includes(myCubicle.cubicle_status) ||
            // Status were changed from Checkout to Ooccupied
            (lastCubicleStatus === CUBICLE_STATUS_CHECKOUT && myCubicle.cubicle_status === CUBICLE_STATUS_OCCUPIED)
        ) {
            dispatch(setNavigateTo(STUDENT_HOME));
        }
        setLastCubicleStatus(myCubicle.cubicle_status)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myCubicle.cubicle_status]);

    return (
        <Container className='px-4 px-md-auto min-vh-56'>
            {(! myCubicle.last_cubicle_log?.procedures || myCubicle.last_cubicle_log.procedures.length === 0)
                ? <CheckoutProceduresForm />
                : <StudentCubicleQueue />
            }
        </Container>
    );
};

export default Checkout;
