import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
import { store } from "./redux/configureStore"
import toast, { Toaster } from 'react-hot-toast';
import MainLayout from "./theme/layout/MainLayout";
import AppSpinner from "./components/ui/AppSpinner";
import NotificationModal from "./components/ui/NotificationModal";
import { addSessionInterceptor, handleAppDataInterceptor, invalidTokenInterceptor, networkErrorInterceptor, otherErrorsInterceptor } from "./interceptors/axiosInterceptors"
import { getUserSuccess, setWebsocketUrl } from "./redux/actions/users.actions";
import { getMyCubicle, resetMyCubicle } from "./redux/actions/my_cubicle.actions";
import { USER_ROLE_STUDENT } from "./redux/reducers/auth.reducer";
import { getProcedures } from "./redux/actions/procedures.actions";
import { MB_BASE_URL, MB_WS_MESSAGES } from "./services/API_CONSTANTS";
import AppWebsocket from "./hooks/AppWebsocket";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.request.use(addSessionInterceptor);
// Order of interceptors is important
axios.interceptors.response.use(null, invalidTokenInterceptor);
axios.interceptors.response.use(null, networkErrorInterceptor);
axios.interceptors.response.use(null, otherErrorsInterceptor);
axios.interceptors.response.use(null, (error) => handleAppDataInterceptor(error, store));

function App() {
    const dispatch = useDispatch();
    const overlay = useSelector(state => state.ui.overlay);
    const toastSelector = useSelector(state => state.ui.toast);

    useEffect(() => {
        if (toastSelector && toastSelector.severity && (toastSelector.summary || toastSelector.detail)) {
            let message = toastSelector.detail;
            if (toastSelector.summary) {
                message = toastSelector.summary + '\n\n' + message;
            }

            let toastOptions = { type: toastSelector.severity };
            if (toastSelector.life) {
                toastOptions.duration = toastSelector.life;
            } else {
                toastOptions.duration = 5000;
            }

            toast(message, toastOptions);
        }
    }, [toastSelector]);

    // RELOAD USER LOGGED IN STORE
    useEffect(() => {
        const userLogged = JSON.parse(localStorage.getItem('ca-user'));
        if (userLogged) {
            dispatch(getUserSuccess(userLogged));
            if (userLogged.role === USER_ROLE_STUDENT) {
                dispatch(getMyCubicle());
            } else {
                dispatch(resetMyCubicle());
            }
            dispatch(getProcedures());
            dispatch(setWebsocketUrl(MB_BASE_URL + MB_WS_MESSAGES + '/' + userLogged.client + '/' + userLogged.username));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let appSpinner;
    if (overlay.loading) {
        appSpinner = <AppSpinner message={overlay.message} />
    }

    return (
        <BrowserRouter>
            <MainLayout>
                <RouterConfig />
            </MainLayout>
            <NotificationModal />
            {appSpinner}
            <Toaster position="top-right" />
            <AppWebsocket />
        </BrowserRouter>
    );
}

export default App;
