import { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormGroup, Input, Button } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { login, loginWithHash } from '../../redux/actions/auth.actions';
import { cleanSession } from '../../utils/index'
import { USER_HOME } from '../../navigation/ROUTE_CONSTANTS';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { setWebsocketUrl } from '../../redux/actions/users.actions';
import { MB_BASE_URL, MB_WS_MESSAGES } from '../../services/API_CONSTANTS';

const Login = () => {
    const dispatch = useDispatch();
    const userLogged = useSelector(state => state.users.userLogged);
    const singleButtonLogin = JSON.parse(localStorage.getItem('ca-sbl'));
    const [useStandardLogin, setUseStandardLogin] = useState(false);

    useEffect(() => {
        cleanSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userLogged) {
            dispatch(setWebsocketUrl(MB_BASE_URL + MB_WS_MESSAGES + '/' + userLogged.client + '/' + userLogged.username));
            dispatch(setNavigateTo(USER_HOME));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLogged]);

    const handleSingleLogin = () => {
        dispatch(loginWithHash({ single_login_hash: singleButtonLogin.single_login_hash }));
    }

    return (
        <Container className='mb-5 min-vh-60'>
            <Row className='mt-5'>
                <Col>
                    {(!useStandardLogin && singleButtonLogin) ? (
                        <Fragment>
                            <div className='vstack col-md-4 mx-auto'>
                                <div className='text-center pb-2 mb-4'>Auto login with button below...</div>
                                <Button color='success' size='lg' onClick={handleSingleLogin}>Login as {singleButtonLogin.username}</Button>
                            </div>
                            <div className='vstack col-md-4 mx-auto mt-5'>
                                <Button color='link' size='sm' onClick={() => setUseStandardLogin(true)}>Use standard login</Button>
                            </div>
                        </Fragment>
                    ) : (
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                username: '',
                                password: '',
                            }}
                            validationSchema={Yup.object({
                                username: Yup.string()
                                    .required('Please enter your username'),
                                password: Yup.string()
                                    .required('Please enter your password'),
                            })}
                            onSubmit={(values) => {
                                dispatch(login(values));
                            }}
                        >
                            {() => (
                                <Form>
                                    <div className='vstack col-md-4 mx-auto'>
                                        <div className='text-center pb-2 mb-4'>Please sign in below...</div>
                                        <FormGroup>
                                            <Field type="text" name="username" placeholder="Username" as={Input} />
                                            <ErrorMessage name="username" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Field type="password" name="password" placeholder="Password" as={Input} />
                                            <ErrorMessage name="password" />
                                        </FormGroup>
                                        <Button color='success' size='lg' type='submit'>Sign in</Button>
                                    </div>

                                    {singleButtonLogin && (
                                        <div className='vstack col-md-4 mx-auto mt-5'>
                                            <Button color='link' size='sm' onClick={() => setUseStandardLogin(false)}>Use single login</Button>
                                        </div>
                                    )}

                                </Form>
                            )}
                        </Formik>
                    )}
                </Col>
            </Row>
        </Container>
    )
};

export default Login;