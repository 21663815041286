import { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CLIENT_ADMIN_CUBICLE_GROUPS } from '../../navigation/ROUTE_CONSTANTS';
import { getOneCubicleGroup } from '../../services/cubicleGroupsService';
import { deleteCubicleGroup } from '../../redux/actions/cubicle_groups.actions';

function ClientAdminCubicleGroupDelete() {
    const dispatch = useDispatch();

    const { cubicleGroupId } = useParams();

    const [rowData, setRowData] = useState('');

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Delete Cubicle Group';
    });

    useEffect(() => {
        getOneCubicleGroup(cubicleGroupId).then((data) => setRowData(data))
    }, [cubicleGroupId]);

    const handleCancel = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_CUBICLE_GROUPS));
    }

    const handleDelete = () => {
        dispatch(deleteCubicleGroup(rowData.id));
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Delete Cubicle Group" crumbList={[{ linkTo: CLIENT_ADMIN_CUBICLE_GROUPS, label: 'Cubicle Groups' }]} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                        <h4 className='pt-2 pb-3 text-center'>Delete Cubicle Group</h4>
                        <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to remove this cubicle group?</h5>
                        <p>Cubicle Group: <strong>{rowData.name}</strong></p>
                    </div>
                </Col>
            </Row>
            <Row>
                <p className="mt-4 mb-4 text-center">
                    <Button color={'success'} className="me-2" onClick={handleDelete}>
                        Delete Cubicle Group
                    </Button>
                    <Button color={'secondary'} onClick={handleCancel} >
                        Cancel
                    </Button>
                </p>
            </Row>
        </Container>
    )
}

export default ClientAdminCubicleGroupDelete
