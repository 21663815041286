import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    CREATE_CUBICLE,
    DELETE_CUBICLE,
    UPDATE_CUBICLE,
    GET_ADMIN_LIST_CUBICLES,
    GET_LIST_CUBICLES,
    getAdminListCubiclesSuccess,
    getCubiclesSuccess,
    GET_CUBICLE_QUEUE,
    getCubicleQueueSuccess,
    GET_LIST_UNASSIGNED_CUBICLES,
    getUnassignedCubiclesSuccess,
} from '../actions/cubicles.actions';
import { createCubicle, getCubicleQueue, getCubicles, getUnassignedCubicles, removeCubicle, updateCubicle } from '../../services/cubiclesService';
import { genericError } from '../actions/error.actions';
import { disableOverlay, enableOverlay, setNavigateTo, setToast } from '../actions/ui.actions';
import { CLIENT_ADMIN_CUBICLES } from '../../navigation/ROUTE_CONSTANTS';
import { TOAST_SEVERITY_SUCCESS } from '../reducers/ui.reducer';

/**
 * getListCubiclesEffect
 */
function* getListCubiclesEffect() {
    try {
        const cubiclesList = yield call(getCubicles);
        if (cubiclesList) {
            yield put(getCubiclesSuccess(cubiclesList));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getUnassignedCubiclesEffect
 */
function* getUnassignedCubiclesEffect() {
    try {
        const unassignedCubicles = yield call(getUnassignedCubicles);
        if (unassignedCubicles) {
            yield put(getUnassignedCubiclesSuccess(unassignedCubicles));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

/**
 * getAdminListCubiclesEffect
 */
function* getAdminListCubiclesEffect() {
    yield put(enableOverlay('Loading cubicles ...'));
    try {
        const cubiclesList = yield call(getCubicles);
        yield put(getAdminListCubiclesSuccess(cubiclesList));
    } catch (err) {
        console.log('error');
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * createCubicleEffect
 */
function* createCubicleEffect({ payload }) {
    try {
        yield put(enableOverlay('Creating cubicle ...'));
        const cubicle = yield call(createCubicle, payload);
        if (cubicle) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The cubicle has been created!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_CUBICLES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * updateCubicleEffect
 */
function* updateCubicleEffect({ payload }) {
    try {
        yield put(enableOverlay('Updating cubicle ...'));
        const cubicle = yield call(updateCubicle, payload);
        if (cubicle) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The cubicle has been updated!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_CUBICLES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * deleteCubicleEffect
 */
function* deleteCubicleEffect({ payload }) {
    try {
        yield put(enableOverlay('Deleting cubicle ...'));
        const cubicle = yield call(removeCubicle, payload);
        if (cubicle) {
            yield put(setToast({
                severity: TOAST_SEVERITY_SUCCESS,
                detail: 'The cubicle has been deleted!',
            }));
            yield put(setNavigateTo(CLIENT_ADMIN_CUBICLES));
        }
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
    yield put(disableOverlay());
}

/**
 * getCubicleQueueEffect
 */
function* getCubicleQueueEffect() {
    try {
        const cubicleQueue = yield call(getCubicleQueue);
        yield put(getCubicleQueueSuccess(cubicleQueue));
    } catch (err) {
        console.log('error:', err);
        yield put(genericError(err));
    }
}

export function* watchGetListCubicles() {
    yield takeEvery(GET_LIST_CUBICLES, getListCubiclesEffect);
}

export function* watchGetUnassignedCubicles() {
    yield takeEvery(GET_LIST_UNASSIGNED_CUBICLES, getUnassignedCubiclesEffect);
}

export function* watchGetAdminListCubicles() {
    yield takeEvery(GET_ADMIN_LIST_CUBICLES, getAdminListCubiclesEffect);
}

export function* watchCreateCubicle() {
    yield takeEvery(CREATE_CUBICLE, createCubicleEffect);
}

export function* watchUpdateCubicle() {
    yield takeEvery(UPDATE_CUBICLE, updateCubicleEffect);
}

export function* watchDeleteCubicle() {
    yield takeEvery(DELETE_CUBICLE, deleteCubicleEffect);
}

// watch getCubicleQueue
export function* watchGetCubicleQueue() {
    yield takeEvery(GET_CUBICLE_QUEUE, getCubicleQueueEffect);
}

function* cubiclesSaga() {
    yield all([
        fork(watchGetListCubicles),
        fork(watchGetAdminListCubicles),
        fork(watchGetUnassignedCubicles),
        fork(watchCreateCubicle),
        fork(watchUpdateCubicle),
        fork(watchDeleteCubicle),
        fork(watchGetCubicleQueue),
    ]);
}

export default cubiclesSaga;
