import { GET_ADMIN_LIST_LOGOFF_TIMES_SUCCESS } from "../actions/logoff_times.actions";

export const FREQUENCY_ONCE = 'once';
export const FREQUENCY_DAILY = 'daily';
export const FREQUENCY_WEEKLY = 'weekly';

export const FREQUENCY_UI_NAMES = {
    [FREQUENCY_ONCE]: 'Once',
    [FREQUENCY_DAILY]: 'Daily',
    [FREQUENCY_WEEKLY]: 'Weekly',
};

export const FREQUENCY_OPTIONS = [
    FREQUENCY_ONCE,
    FREQUENCY_DAILY,
    FREQUENCY_WEEKLY,
];


export const LOGOFF_ROLE_STUDENT = 'student';
export const LOGOFF_ROLE_FACULTY = 'faculty';
export const LOGOFF_ROLE_FACULTY_AND_STUDENT = 'faculty_and_student';

export const LOGOFF_ROLE_UI_NAMES = {
    [LOGOFF_ROLE_STUDENT]: 'Student',
    [LOGOFF_ROLE_FACULTY]: 'Faculty',
    [LOGOFF_ROLE_FACULTY_AND_STUDENT]: 'Faculty and Student'
};

export const LOGOFF_ROLE_OPTIONS = [
    LOGOFF_ROLE_STUDENT,
    LOGOFF_ROLE_FACULTY,
    LOGOFF_ROLE_FACULTY_AND_STUDENT
];


const initialAdminLogoffTimesState = {
    adminLogoffTimesList: [],
    next: null,
    previous: null,
    count: 0
};

export const AdminLogoffTimesReducer = (state = initialAdminLogoffTimesState, action) => {
    switch (action.type) {
        case GET_ADMIN_LIST_LOGOFF_TIMES_SUCCESS:
            return {
                ...state,
                adminLogoffTimesList: action.payload.results,
                next: action.payload.next,
                previous: action.payload.previous,
                count: action.payload.count,
            }
        default:
            return state;
    }
}
