import {createAction} from "@reduxjs/toolkit";

// CubicleGroup type
export const CREATE_CUBICLE_GROUP = '[CUBICLE_GROUP] Cubicle group created';
export const UPDATE_CUBICLE_GROUP = '[CUBICLE_GROUP] Cubicle group updated';
export const DELETE_CUBICLE_GROUP = '[CUBICLE_GROUP] Cubicle group deleted';
export const GET_LIST_CUBICLE_GROUPS = '[CUBICLE_GROUPS] Getting list cubicle groups';
export const GET_LIST_CUBICLE_GROUPS_SUCCESS = '[CUBICLE_GROUPS] Getting cubicle groups success!';
export const GET_ADMIN_LIST_CUBICLE_GROUPS = '[CUBICLE_GROUPS] Getting admin list cubicle groups';
export const GET_ADMIN_LIST_CUBICLE_GROUPS_SUCCESS = '[CUBICLE_GROUPS] Getting admin cubicle groups success!';

// CubicleGroup actions
export const createCubicleGroup = createAction(CREATE_CUBICLE_GROUP);
export const updateCubicleGroup = createAction(UPDATE_CUBICLE_GROUP);
export const deleteCubicleGroup = createAction(DELETE_CUBICLE_GROUP);
export const getCubicleGroups = createAction(GET_LIST_CUBICLE_GROUPS);
export const getCubicleGroupsSuccess = createAction(GET_LIST_CUBICLE_GROUPS_SUCCESS);
export const getAdminListCubicleGroups = createAction(GET_ADMIN_LIST_CUBICLE_GROUPS);
export const getAdminListCubicleGroupsSuccess = createAction(GET_ADMIN_LIST_CUBICLE_GROUPS_SUCCESS);
