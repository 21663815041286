import { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBreadcrumb from '../../components/ui/AppBreadcrumb';
import { setNavigateTo } from '../../redux/actions/ui.actions';
import { CLIENT_ADMIN_CUBICLES } from '../../navigation/ROUTE_CONSTANTS';
import { deleteCubicle } from '../../redux/actions/cubicles.actions';
import { getOneCubicle } from '../../services/cubiclesService';

function ClientAdminCubicleDelete() {
    const dispatch = useDispatch();

    const { cubicleId } = useParams();

    const [rowData, setRowData] = useState('');

    useEffect(() => {
        document.title = process.env.REACT_APP_SITE_TITLE + ' - Delete Cubicle';
    });

    useEffect(() => {
        getOneCubicle(cubicleId).then((data) => setRowData(data))
    }, [cubicleId]);

    const handleCancel = () => {
        dispatch(setNavigateTo(CLIENT_ADMIN_CUBICLES));
    }

    const handleDelete = () => {
        dispatch(deleteCubicle(rowData.id));
    }

    return (
        <Container className='mb-5 pb-5'>
            <Row>
                <Col>
                    <AppBreadcrumb headText="Delete Cubicle" crumbList={[{ linkTo: CLIENT_ADMIN_CUBICLES, label: 'Cubicles' }]} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='vstack col-md-4 mx-auto mt-4 text-center'>
                        <h4 className='pt-2 pb-3 text-center'>Delete Cubicle</h4>
                        <p>Under certain conditions to disable the cubicle could be enough.</p>
                        <h5 className='mt-3 mb-4 pt-2 pb-2'>Are you sure you want to remove this cubicle?</h5>
                        <p>Cubicle: <strong>{rowData.name}</strong></p>
                    </div>
                </Col>
            </Row>
            <Row>
                <p className="mt-4 mb-4 text-center">
                    <Button color={'success'} className="me-2" onClick={handleDelete}>
                        Delete Cubicle
                    </Button>
                    <Button color={'secondary'} onClick={handleCancel} >
                        Cancel
                    </Button>
                </p>
            </Row>
        </Container>
    )
}

export default ClientAdminCubicleDelete
